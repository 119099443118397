import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import ParcelsModal from 'views/developer/dashboard/lead/components/ParcelsModal'
import CheckFatIcon from 'components/SVG/Icons/CheckFatIcon'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import CloseIcon from 'components/SVG/Icons/CloseIcon'
import MapIcon from 'components/SVG/Icons/MapIcon'
import {
  CRITERON_TYPE,
  CriterionData,
  GAEventAction,
  GAEventContext,
  GAEventElement,
} from 'types'

import theme from 'styles/theme'

const Criterion = ({
  title,
  description,
  type,
  value,
  items,
  mapPictureLink,
  handleChangePicturePath,
  picturePath,
  typeKey,
}: {
  title: string
  description?: string
  type: string
  value?: string | boolean
  items?: CriterionData[]
  mapPictureLink?: string
  handleChangePicturePath: (value?: string) => void
  picturePath: string
  typeKey?: string
}) => {
  const [isButtonActive, setIsButtonActive] = useState(false)
  const { t } = useTranslation()

  const bgColor =
    (type === CRITERON_TYPE.WARNING && 'bg-sun') ||
    (type === CRITERON_TYPE.OK && 'bg-grass') ||
    (type === CRITERON_TYPE.NOT_OK && 'bg-coquelicot')

  const textColor =
    (type === CRITERON_TYPE.WARNING && 'text-strongSun') ||
    (type === CRITERON_TYPE.OK && 'text-strongGrass') ||
    (type === CRITERON_TYPE.NOT_OK && 'text-white')

  const strongColorJs =
    (type === CRITERON_TYPE.WARNING && theme.colors.strongSun) ||
    (type === CRITERON_TYPE.OK && theme.colors.strongGrass) ||
    (type === CRITERON_TYPE.NOT_OK && 'white') ||
    'black'

  const renderValueOrIcon = () => {
    if (value !== undefined && value !== null) {
      if (typeof value === 'boolean') {
        return (
          <div className="flex items-center">
            {value === true && (
              <CheckFatIcon size={20} color={theme.colors.strongGrass} />
            )}
            {value === false && <CloseIcon size={20} color="white" />}
          </div>
        )
      }
      return (
        <div className="text-left text-sm font-bold lg:pr-4 lg:text-right">
          {value}
        </div>
      )
    } else {
      if (type === CRITERON_TYPE.OK) {
        return <CheckFatIcon size={20} color={strongColorJs} />
      } else if (type === CRITERON_TYPE.NOT_OK) {
        return <CloseIcon size={20} color={strongColorJs} />
      }
      return null
    }
  }

  const handleButtonClick = () => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.IMAGE,
      action: GAEventAction.CHANGE,
    })

    if (picturePath === mapPictureLink) handleChangePicturePath()
    else if (mapPictureLink) handleChangePicturePath(mapPictureLink!)
    setIsButtonActive(!isButtonActive)
  }

  useEffect(() => {
    if (picturePath !== mapPictureLink) setIsButtonActive(false)
    if (picturePath === mapPictureLink) setIsButtonActive(true)
  }, [picturePath, mapPictureLink])

  return (
    <div
      className={`${textColor} flex w-full flex-1 flex-wrap items-start gap-4 rounded-lg lg:items-center ${bgColor} flex-col overflow-hidden lg:flex-row`}
    >
      {mapPictureLink && (
        <div
          className={`relative self-stretch p-4 hover:cursor-pointer lg:w-[80px] lg:min-w-[80px]`}
          onClick={handleButtonClick}
        >
          <div
            className={`absolute inset-0 ${bgColor} bg-blend-normal ${isButtonActive ? 'brightness-75' : 'brightness-90'}`}
          />

          <div className="relative flex h-full flex-col items-center justify-center">
            <MapIcon size={25} color={strongColorJs} />
            <span className={`${textColor} text-sm`}>
              {isButtonActive ? t('commons.hide') : t('commons.view')}
            </span>
          </div>
        </div>
      )}

      <div
        className={`${!mapPictureLink && 'lg:pl-5'} flex flex-1 flex-col items-start justify-start gap-10 p-4 lg:flex-row lg:items-center lg:justify-between lg:gap-4 lg:p-0 lg:py-5`}
      >
        <div>
          <div className="text-sm font-bold">{t(title)}</div>
          {description && <div className="text-sm">{t(description)}</div>}

          {items &&
            typeKey !== 'parcels' &&
            items.map((item) => (
              <div className="flex items-center gap-2 text-sm" key={item.title}>
                {item.type === 'ok' && (
                  <CheckFatIcon color={strongColorJs} size={15} />
                )}

                {item.type === 'nok' && (
                  <CloseIcon color={strongColorJs} size={15} />
                )}
                {t(item.title)}
              </div>
            ))}
        </div>
        {renderValueOrIcon()}
      </div>

      {items && typeKey === 'parcels' && !!items.length && (
        <ParcelsModal items={items} />
      )}
    </div>
  )
}

export default Criterion

import { Progress } from 'antd'

import CheckFatIcon from 'components/SVG/Icons/CheckFatIcon'

import theme from 'styles/theme'

const getStrokeColor = (percent: number): string => {
  if (percent < 50) return theme.colors.coquelicot
  if (percent < 75) return theme.colors.sun
  return theme.colors.grass
}

interface ProgressCircleProps {
  percent: number
  size: number
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({ percent, size }) => {
  return (
    <Progress
      type="circle"
      strokeColor={getStrokeColor(percent)}
      strokeWidth={15}
      percent={percent}
      size={size}
      trailColor={theme.colors.wind}
      format={() =>
        percent === 100 && (
          <div className="flex justify-center">
            <CheckFatIcon size={size / 3} color={theme.colors.grass} />
          </div>
        )
      }
    />
  )
}

export default ProgressCircle

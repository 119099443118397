import { Outlet } from '@tanstack/react-router'

import MobileMenu from 'views/developer/dashboard/components/MobileMenu'
import Sidebar from 'views/developer/dashboard/components/Sidebar'
import PageContainer from 'components/commons/PageContainer'

const DeveloperDashboardWrapper = () => {
  return (
    <PageContainer headerExtra={<MobileMenu />}>
      <div className="scroll-none relative flex gap-10">
        <div className="hidden lg:block">
          <Sidebar />
        </div>
        <div className="flex flex-1 flex-col gap-10">
          <Outlet />
        </div>
      </div>
    </PageContainer>
  )
}

export default DeveloperDashboardWrapper

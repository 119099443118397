import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import Category from 'views/developer/dashboard/lead/components/Category'
import VoteBar from 'views/developer/dashboard/lead/components/VoteBar'
import { Category as CategoryType, Project } from 'types'
import { useProjectStatus } from 'hooks/useProjectData'
import CustomCard from 'components/commons/CustomCard'
import { PROJECT_LIKE_STATUS } from 'types'

const LeadPageRightPart = ({
  project,
  picturePath,
  handleChangePicturePath,
  projectUuid,
  updateProjectStatus,
}: {
  project: Project
  picturePath: string
  handleChangePicturePath: (path?: string) => void
  projectUuid: string
  updateProjectStatus: (type: PROJECT_LIKE_STATUS) => void
}) => {
  const { t } = useTranslation()
  const [showVoteBar, setShowVoteBar] = useState(false)
  const { projectStatus, setProjectStatus } = useProjectStatus(projectUuid)

  useEffect(() => {
    setShowVoteBar(projectStatus === undefined)
  }, [projectStatus])

  const handleVote = (type: PROJECT_LIKE_STATUS) => {
    setProjectStatus(type)
    updateProjectStatus(type)
    setShowVoteBar(false)
  }

  return (
    <div id="right-part" className="flex flex-1 flex-col gap-4">
      {showVoteBar && (
        <VoteBar updateProjectStatus={handleVote} projectUuid={projectUuid} />
      )}

      <CustomCard
        title={t(
          'views.developer.dashboard.lead.leadPageRightPart.criteriaMatchingSpecification'
        )}
      >
        <div className="ml-5 flex flex-col">
          {project?.data?.map((category: CategoryType) => (
            <div
              key={category.title}
              className="border-0 border-wind py-7 [&:not(:last-child)]:border-b-[1px]"
            >
              <Category
                category={category}
                picturePath={picturePath}
                handleChangePicturePath={handleChangePicturePath}
              />
            </div>
          ))}
        </div>
      </CustomCard>
    </div>
  )
}

export default LeadPageRightPart

import ReactGA from 'react-ga4'

import { GAEventAction, GAEventContext, GAEventElement } from 'types'

const sendGACustomEvent = ({
  context,
  element,
  action,
}: {
  context: GAEventContext
  element: GAEventElement
  action: GAEventAction
}) => {
  ReactGA.event(`${context}:${element}:${action}`)
}

export default sendGACustomEvent

import { useTranslation } from 'react-i18next'
import { MenuProps, notification } from 'antd'

import FileArrowDownIcon from 'components/SVG/Icons/FileArrowDownIcon'
import { GAEventAction, GAEventContext, GAEventElement } from 'types'
import PolygonIcon from 'components/SVG/Icons/PolygonIcon'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import Button from 'components/commons/Button'
import { FALLBACK_LANGUAGE } from 'utils/i18n'
import getLeadKml from 'actions/get-lead-kml'

const LeadPageHeader = ({
  infoMemoUrl,
  projectUuid,
}: {
  infoMemoUrl?: string
  projectUuid: string
}) => {
  const { t } = useTranslation()

  const handleOpenFile = () => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.INFO_MEMO,
      action: GAEventAction.DOWNLOAD,
    })

    const lang = localStorage.getItem('i18nextLng') || FALLBACK_LANGUAGE

    if (infoMemoUrl) {
      const a = document.createElement('a')
      a.href = `/assets/documents/projects/${projectUuid}/${lang}/${infoMemoUrl}`
      a.download = `project-memo-${projectUuid}.pdf`
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }

  const handleDownloadKML = async () => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.KML,
      action: GAEventAction.DOWNLOAD,
    })

    try {
      const data = await getLeadKml({ uuid: projectUuid })
      const blob = new Blob([data])
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `project-${projectUuid}.kml`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    } catch {
      notification.error({
        message: t('errors.unexpectedError'),
        description: t('errors.tryAgainLater'),
      })
    }
  }

  const items: MenuProps['items'] = [
    {
      label: t(
        'views.developer.dashboard.lead.leadPageHeader.downloadInfoMemo'
      ),
      key: '1',
      icon: (
        <div>
          <FileArrowDownIcon size={20} color={'black'} />
        </div>
      ),
      onClick: handleOpenFile,
    },
    {
      label: t('views.developer.dashboard.lead.leadPageHeader.downloadKML'),
      key: '2',
      icon: (
        <div>
          <PolygonIcon size={20} color={'black'} />
        </div>
      ),
      onClick: handleDownloadKML,
    },
  ]

  return (
    <div className="flex flex-col items-start justify-end gap-2 md:flex-row md:items-center">
      <Button style={'primary'} items={items}>
        {t('commons.download')}
      </Button>
    </div>
  )
}

export default LeadPageHeader

import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'

const getSpecification = async ({ id }: { id: string }) => {
  const result = await apiRequest({
    type: RequestType.GET,
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/specifications/${id}`,
  })

  return result
}

export default getSpecification

import { useState, useCallback, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { Project, PROJECT_LIKE_STATUS } from 'types'
import { encrypt, decrypt } from 'utils/crypto'
import getLeads from 'actions/get-leads'
import getLead from 'actions/get-lead'

// TODO
// Remove all id references when all clients' local storage
// have been updated with uuid
interface ProjectInStorage {
  id?: number
  uuid: string
  isLiked?: PROJECT_LIKE_STATUS
  isViewable: boolean
}

// TODO
// Remove when all clients' local storage
// have been updated with uuid
const UUID_MAPPINGS = {
  '1': '0ef56efe-c04f-b430-c76b-3713c1c67af3',
  '2': '9198ba21-ea60-8e07-4be0-40463255917b',
  '3': '40fcf622-68bb-5a53-3ecb-c2427fa7d718',
  '4': '15738e94-4e9c-dda0-0664-9a1d253f19cf',
  '5': 'e140c467-029a-81ea-cedd-990406a08ddf',
  '6': '3dc66e1e-69f1-0746-0f2b-054c2edfe8bb',
  '7': 'fd3dd191-92d4-0074-ac23-1a0de3f6e0a2',
  '8': 'c1a15668-73fc-38e9-acfb-e788a9715080',
  '9': '0fb093bf-01ba-3ce0-f6c0-05fab74ba33c',
  '10': '89079091-6b9c-0ad0-2eb0-86cb52fabb94',
} as const

const isValidProjectsArray = (data: unknown): data is ProjectInStorage[] => {
  return (
    Array.isArray(data) &&
    data.every(
      (project) =>
        (typeof project.uuid === 'string' || typeof project.id === 'string') &&
        typeof project.isViewable === 'boolean'
    )
  )
}

// TODO
// Remove when all clients' local storage
// have been updated with uuid
const transformUuid = (uuid: string): string => {
  return UUID_MAPPINGS[uuid as keyof typeof UUID_MAPPINGS] || uuid
}

export const useProjects = () => {
  const [projects, setProjects] = useState<ProjectInStorage[]>([])

  const { data, isLoading, error } = useQuery<Project[]>({
    queryKey: ['projects'],
    queryFn: getLeads,
    retry: false,
  })

  useEffect(() => {
    if (data) {
      let projectsToSet: ProjectInStorage[]
      const storedData = localStorage.getItem('projects')

      if (storedData) {
        try {
          const decryptedData = decrypt(storedData)
          const parsedData = JSON.parse(decryptedData)

          if (isValidProjectsArray(parsedData)) {
            projectsToSet = parsedData.map((storedProject) => {
              const { uuid, id, ...rest } = storedProject

              return {
                // TODO
                // Refer to uuid only
                uuid: transformUuid(id?.toString() || uuid),
                ...rest,
                id: undefined,
              }
            })
          } else {
            throw new Error('Invalid data structure')
          }
        } catch (error) {
          console.warn('Error decrypting or parsing, initializing projects')
          projectsToSet = initializeProjects(data)
        }
      } else {
        projectsToSet = initializeProjects(data)
      }

      const encryptedProjects = encrypt(JSON.stringify(projectsToSet))
      localStorage.setItem('projects', encryptedProjects)
      setProjects(projectsToSet)
    }
  }, [data])

  const initializeProjects = (projects: Project[]): ProjectInStorage[] => {
    const smallestId = projects[0]?.uuid
    const initialProjects = [
      {
        uuid: smallestId,
        isLiked: undefined,
        isViewable: true,
      },
    ]
    const encryptedInitialProjects = encrypt(JSON.stringify(initialProjects))
    localStorage.setItem('projects', encryptedInitialProjects)
    return initialProjects
  }

  const getProjectById = (uuid: string) => {
    return useQuery<Project>({
      queryKey: ['project', uuid],
      queryFn: () => getLead({ uuid }),
    })
  }

  const getProjectStatus = useCallback(
    (uuid: string) => {
      const projectInStorage = projects.find((project) => project.uuid === uuid)
      if (projectInStorage) {
        return projectInStorage.isViewable
      }
      if (data) {
        const currentIndex = data.findIndex((project) => project.uuid === uuid)
        if (currentIndex > 0) {
          const previousId = data[currentIndex - 1].uuid
          const previousProjectInStorage = projects.find(
            (project) => project.uuid === previousId
          )
          if (
            previousProjectInStorage &&
            previousProjectInStorage.isLiked !== undefined
          ) {
            return true
          }
        }
      }
      return false
    },
    [projects, data]
  )

  const getProjectLikeStatus = useCallback(
    (uuid: string) => {
      return projects.find((project) => project.uuid === uuid)?.isLiked
    },
    [projects]
  )

  const updateProject = useCallback(
    (uuid: string, updates: Partial<ProjectInStorage>) => {
      setProjects((prevProjects) => {
        const index = prevProjects.findIndex((project) => project.uuid === uuid)

        if (index === -1) {
          const newProject = { uuid, isViewable: true, ...updates }
          const updatedProjects = [...prevProjects, newProject]
          const encryptedProjects = encrypt(JSON.stringify(updatedProjects))
          localStorage.setItem('projects', encryptedProjects)
          return updatedProjects
        } else {
          const updatedProjects = [...prevProjects]
          updatedProjects[index] = { ...updatedProjects[index], ...updates }
          const encryptedProjects = encrypt(JSON.stringify(updatedProjects))
          localStorage.setItem('projects', encryptedProjects)
          return updatedProjects
        }
      })
    },
    []
  )

  return {
    projects: data,
    loading: isLoading,
    error,
    getProjectById,
    getProjectStatus,
    getProjectLikeStatus,
    updateProject,
  }
}

export default useProjects

import { useTranslation } from 'react-i18next'

import HighlightedText from 'components/commons/HighlightedText'

const HomeHeader = () => {
  const { t } = useTranslation()

  return (
    <div className="left-0 right-0 top-0 mb-5 flex w-[100%] justify-center overflow-hidden bg-soil md:absolute md:top-[100px] md:h-[500px]">
      <div className="absolute -right-[20px] -top-[20px] hidden h-[540px] w-[100%] overflow-hidden rounded-es-full rounded-ss-full border-[20px] border-solid border-water md:block md:w-[40%]">
        <img
          src="/assets/images/home-background.jpg"
          alt={t('views.home.homeHeader.renewableEnergyPictureAltTag')}
          className="h-full w-full object-cover"
        />
      </div>

      <div
        className={`flex h-full w-[100%] max-w-[1500px] flex-col md:w-[90%] md:flex-row`}
      >
        <div className="relative h-[400px] w-[100%] md:hidden">
          <img
            src="/assets/images/home-background.jpg"
            alt={t('views.home.homeHeader.renewableEnergyPictureAltTag')}
            className="h-full w-full object-cover"
            style={{ objectFit: 'cover' }}
          />
        </div>
        <div className="flex w-[100%] flex-col justify-center px-10 py-10 text-white md:w-[60%] md:px-0 md:pr-20">
          <div className="text-[24px] font-extrabold md:text-[43px]">
            {t('views.home.homeHeader.titleFirstPart')}{' '}
            <HighlightedText
              text={t('views.home.homeHeader.highlightedPart')}
            />{' '}
            {t('views.home.homeHeader.titleSecondPart')}
          </div>
          <div className="mt-5 text-lg">
            {t('views.home.homeHeader.globalLocalStandpoints')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeHeader

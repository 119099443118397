import ReviewStatusBadge from 'components/commons/ReviewStatusBadge'

const PictureSection = ({
  picture,
  area,
  isViewable,
  isLiked,
}: {
  picture: string
  area: string
  isViewable?: boolean
  isLiked?: string
}) => {
  return (
    <div
      className="relative h-[300px] w-[100%] overflow-hidden rounded-xl"
      onContextMenu={!isViewable ? (e) => e.preventDefault() : undefined}
    >
      <img
        key={Date.now()}
        src={picture}
        alt="Project image"
        className={!isViewable ? 'pointer-events-none blur-md' : ''}
        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        draggable="false"
      />

      <div className="absolute bottom-0 left-0 right-0 h-[30px] bg-black opacity-30"></div>

      <div className="absolute bottom-[30px] left-0 right-0 h-[70px] bg-gradient-to-t from-black/30 to-transparent"></div>

      {isViewable && (
        <span
          className={`absolute bottom-2 left-2 text-lg font-bold text-white`}
        >
          {area}
        </span>
      )}

      {isViewable && (
        <div className="absolute right-2 top-2">
          <ReviewStatusBadge isLiked={isLiked} />
        </div>
      )}
    </div>
  )
}

export default PictureSection

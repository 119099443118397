import BugIcon from 'components/SVG/Icons/BugIcon'

import theme from 'styles/theme'

const ErrorDisplay = ({
  title,
  message,
  error,
  icon,
}: {
  title?: string
  message?: string
  error?: Error | boolean | null
  icon?: ({ size, color }: { size: number; color: string }) => JSX.Element
}) => {
  const Icon = icon || BugIcon

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <Icon color={theme.colors.strongWind} size={40} />
      <div className="mt-5 text-xl font-bold">
        {title || 'An error occured'}
      </div>
      <div>
        {message ||
          (error instanceof Error ? error.message : 'Please try again later.')}
      </div>
    </div>
  )
}

export default ErrorDisplay

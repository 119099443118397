import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/react-router'
import { useEffect } from 'react'

import RestrictedWarningSection from 'components/commons/LeadCard/components/RestrictedWarningSection'
import CriteriaProgressSection from 'components/commons/LeadCard/components/CriteriaProgressSection'
import PictureSection from 'components/commons/LeadCard/components/PictureSection'
import { useProjectData } from 'hooks/useProjectData'
import { PROJECT_LIKE_STATUS, Project } from 'types'
import Button from 'components/commons/Button'

const LeadCard = ({
  project,
  isLiked,
}: {
  project: Project
  isLiked?: string
}) => {
  const { t } = useTranslation()

  const handleClick = () => {
    sessionStorage.setItem('scrollPosition', window.scrollY.toString())
  }

  const { isViewable } = useProjectData(project.uuid)

  useEffect(() => {
    const handleRouteChange = () => {
      sessionStorage.setItem('scrollPosition', window.scrollY.toString())
    }

    window.addEventListener('beforeunload', handleRouteChange)

    return () => {
      window.removeEventListener('beforeunload', handleRouteChange)
    }
  }, [])

  return (
    <Link
      to={`/developer/dashboard/project/${project.uuid}`}
      onClick={isViewable ? handleClick : (e) => e.preventDefault()}
      className="block h-full"
    >
      <div
        className={`flex h-full flex-col rounded-2xl bg-white p-5 drop-shadow-sm ${
          isViewable ? 'hover:cursor-pointer' : 'hover:cursor-default'
        } ${
          isLiked === PROJECT_LIKE_STATUS.LIKED
            ? 'border-4 border-grass'
            : 'border-4 border-white'
        }`}
      >
        <PictureSection
          isViewable={isViewable}
          isLiked={isLiked}
          area={project.area}
          picture={`/assets/images/projects/${project.uuid}/${project.mainMapPicturePath}`}
        />

        <div className="my-7 flex flex-1 items-center justify-center">
          {isViewable ? (
            <CriteriaProgressSection scores={project.scores} />
          ) : (
            <RestrictedWarningSection />
          )}
        </div>

        <div>
          <Button
            style={isViewable ? 'primary' : 'disabled'}
            loading={false}
            className="w-full"
          >
            {t('commonsComponents.leadCard.actionButtonMessage')}
          </Button>
        </div>
      </div>
    </Link>
  )
}

export default LeadCard

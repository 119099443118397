import ReactDOM from 'react-dom/client'

import QueryClientProvider from 'components/commons/QueryClientProvider'
import App from './App'

import './styles/globals.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <QueryClientProvider>
    <App />
  </QueryClientProvider>
)

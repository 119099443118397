import { useTranslation } from 'react-i18next'

import InicioLogomarkIcon from 'components/SVG/Icons/InicioLogomarkIcon'

import theme from 'styles/theme'

const EuropeActivities = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-1 flex-col justify-center gap-10 md:flex-row">
      <div className="flex items-center justify-center rounded-2xl bg-soil px-20 py-10 drop-shadow-sm transition-hover-up">
        <div className="max-w-[300px]">
          <img
            src={'/assets/images/europe-activities.png'}
            alt={t('views.home.europeActivities.pictureAltTag')}
            className="object-contain"
            style={{ objectFit: 'contain' }}
            width={300}
            height={300}
          />
        </div>
      </div>

      <div className="md flex flex-col md:w-[30%]">
        <span className="order-2 pb-10 text-2xl font-bold md:order-1">
          {t('views.home.europeActivities.expandingSolarDevelopment')}
        </span>

        <div className="order-3 flex-grow md:order-2">
          <span className="mb-3 inline-block">
            {t('views.home.europeActivities.landCriticalForSolar')}
          </span>

          <span className="inline-block">
            {t('views.home.europeActivities.expandingThroughEurope')}
          </span>
        </div>

        <div className="mdpt-5 order-1 mt-auto flex flex-col gap-3 pb-10 md:order-3 md:pt-10">
          <div className="flex items-center justify-start gap-2">
            <InicioLogomarkIcon color={theme.colors.water} size={25} />
            <div className="font-secondary text-xs uppercase">
              {t('views.home.europeActivities.futureDevelopment')}
            </div>
          </div>

          <div className="flex items-center gap-2">
            <InicioLogomarkIcon color={theme.colors.corn} size={25} />
            <div className="font-secondary text-xs uppercase">
              {t('views.home.europeActivities.existingActivities')}
            </div>
          </div>

          <div className="flex items-center gap-2">
            <InicioLogomarkIcon color={theme.colors.grass} size={25} />
            <div className="font-secondary text-xs uppercase">
              {t('views.home.europeActivities.headquarters')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EuropeActivities

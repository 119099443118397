import { useQueryClient } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { Popover, Table } from 'antd'
import { useEffect } from 'react'

import DeveloperDashboardPageContent from 'views/developer/dashboard/components/DeveloperDashboardPageContent'
import { Criterion, GAEventAction, GAEventContext, GAEventElement } from 'types'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import CustomCard from 'components/commons/CustomCard'
import useSpecification from 'hooks/useSpecification'
import InfoIcon from 'components/SVG/Icons/InfoIcon'

import theme from 'styles/theme'

const Specification = () => {
  const { id } = useParams({ from: '/developer/dashboard/specification/$id' })
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { specification, isLoading, error } = useSpecification(id)

  const handlePopoverOpenChange = (isOpen: boolean) => {
    sendGACustomEvent({
      context: GAEventContext.SPECIFICATION,
      element: GAEventElement.POPOVER,
      action: isOpen ? GAEventAction.OPEN : GAEventAction.CLOSE,
    })
  }

  const renderDescriptionValue = (criterion: Criterion) => {
    return (
      <>
        <div>{criterion.description}</div>
        {criterion.items && (
          <ul className="pl-4">
            {criterion.items.map((item, index) => (
              <li className="text-xs" key={index}>
                - {item.title}
              </li>
            ))}
          </ul>
        )}
      </>
    )
  }

  const renderCategoryTables = () => {
    return specification?.data?.map((category, index) => {
      const columns = [
        {
          title: t('commons.criteria'),
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: t('commons.description'),
          dataIndex: 'description',
          key: 'description',
          render: (_: unknown, record: Criterion) =>
            renderDescriptionValue(record),
        },
        {
          title: t('views.developer.dashboard.specification.acceptedValue'),
          dataIndex: 'value',
          key: 'value',
        },
      ]

      return (
        <CustomCard key={index} title={category.title}>
          <Table
            dataSource={category.criteria}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.title}
          />
        </CustomCard>
      )
    })
  }

  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: ['specification', id] })
    }
  }, [id, queryClient])

  return (
    <DeveloperDashboardPageContent
      isLoading={isLoading}
      analyticsPageTitle={`Specification ${id}`}
      error={error}
      errorTitle={t('errors.cantFindData')}
      errorMessage={t('errors.tryAgainLater')}
      childrenExtra={
        <Popover
          content={
            <span className="inline-block max-w-[300px] text-sm">
              {t('views.developer.dashboard.specification.popoverContent')}
            </span>
          }
          title={t('views.developer.dashboard.specification.popoverTitle')}
          onOpenChange={handlePopoverOpenChange}
        >
          <h2 className="flex items-center gap-2 font-bold text-strongWind hover:cursor-default">
            {t('views.developer.dashboard.specification.popoverTitle')}
            <InfoIcon size={20} color={theme.colors.strongWind} />
          </h2>
        </Popover>
      }
    >
      {renderCategoryTables()}
    </DeveloperDashboardPageContent>
  )
}

export default Specification

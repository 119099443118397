import { PROJECT_LIKE_STATUS } from 'types'
import useProjects from 'hooks/useProjects'

export const useProjectData = (projectUuid: string) => {
  const { getProjectById, getProjectStatus } = useProjects()

  const { data: project, isLoading, error } = getProjectById(projectUuid)

  const isViewable = getProjectStatus(projectUuid)

  return {
    project: project || null,
    isViewable,
    isLoading,
    error,
  }
}

export const useProjectStatus = (projectUuid: string) => {
  const { getProjectLikeStatus, updateProject } = useProjects()

  const updateProjectStatus = (type: PROJECT_LIKE_STATUS) => {
    updateProject(projectUuid, { isLiked: type, isViewable: true })
    return true
  }

  return {
    projectStatus: getProjectLikeStatus(projectUuid),
    updateProjectStatus,
    setProjectStatus: (status: PROJECT_LIKE_STATUS) =>
      updateProject(projectUuid, { isLiked: status }),
  }
}

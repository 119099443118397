const MapIcon = ({ color, size }: { color: string; size: string | number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      height={size}
      width={size}
    >
      <rect width="256" height="256" fill="none" />
      <line
        x1="96"
        y1="184"
        x2="96"
        y2="40"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        x1="160"
        y1="72"
        x2="160"
        y2="216"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <polygon
        points="96 184 32 200 32 56 96 40 160 72 224 56 224 200 160 216 96 184"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  )
}

export default MapIcon

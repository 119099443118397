import { useTranslation } from 'react-i18next'

import HighlightedText from 'components/commons/HighlightedText'
import LoginForm from 'views/login/components/LoginForm'
import CustomCard from 'components/commons/CustomCard'
import Logo from 'components/SVG/InicioTextLogo'

const LoginPage = () => {
  const { t } = useTranslation()

  return (
    <div className="relative flex h-screen items-center justify-center">
      <img
        src="/assets/images/login-background.jpg"
        alt={t('views.login.loginBackgroundImageAlt')}
        className="absolute left-0 top-0 h-full w-full object-cover"
        style={{ zIndex: -1 }}
      />

      <div className="z-10 flex w-[90%] max-w-[450px] drop-shadow-lg">
        <CustomCard
          title={<Logo height={'15'} color={'black'} />}
          containerClassName={
            'h-full w-full transition-all duration-500 ease-in-out'
          }
          headerExtra={
            <HighlightedText
              text={
                <span className="font-secondary uppercase">
                  {t('commons.marketplace')}
                </span>
              }
            />
          }
        >
          <span className="block pb-2 pt-4 text-sm">
            {t('views.login.loginFormDescription')}
          </span>
          <LoginForm />
        </CustomCard>
      </div>
    </div>
  )
}

export default LoginPage

import { useTranslation } from 'react-i18next'

import DeveloperDashboardPageContent from 'views/developer/dashboard/components/DeveloperDashboardPageContent'
import NewSpecificationForm from 'views/developer/dashboard/newSpecification/components/NewSpecificationForm'
import CustomCard from 'components/commons/CustomCard'

const NewSpecification = () => {
  const { t } = useTranslation()

  return (
    <DeveloperDashboardPageContent analyticsPageTitle={`Share your needs`}>
      <div className="flex flex-col gap-10 md:flex-row">
        <div className="w-full md:w-1/2">
          <h2 className="font-bold">
            {t('views.developer.dashboard.newSpecification.whyShareTitle')}
          </h2>
          <span className="text-sm">
            {t('views.developer.dashboard.newSpecification.whyShareText')}
          </span>
        </div>
        <div className="w-full md:w-1/2">
          <h2 className="font-bold">
            {t('views.developer.dashboard.newSpecification.whatNextTitle')}
          </h2>
          <span className="text-sm">
            {t('views.developer.dashboard.newSpecification.whatNextText')}
          </span>
        </div>
      </div>
      <CustomCard title={t('commons.shareYourNeeds')}>
        <NewSpecificationForm />
      </CustomCard>
    </DeveloperDashboardPageContent>
  )
}

export default NewSpecification

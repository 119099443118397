import { ReactNode } from 'react'

type CustomCardType = {
  title?: string | ReactNode
  children?: ReactNode
  headerExtra?: ReactNode
  bgColor?: string
  containerClassName?: string
  contentClassName?: string
}

const CustomCard = ({
  title,
  children,
  headerExtra,
  bgColor,
  containerClassName,
  contentClassName,
}: CustomCardType) => {
  return (
    <div
      className={`custom-card-container w-full drop-shadow-sm ${containerClassName}`}
    >
      <div className={`rounded-2xl p-5 ${bgColor || 'bg-white'}`}>
        {title && (
          <div
            className={`mb-4 flex items-center justify-between border-0 border-b-[1px] border-solid border-wind pb-4`}
          >
            <div className="title-lg flex h-[32px] items-center">{title}</div>

            {headerExtra && (
              <div className="flex items-center text-[14px]">{headerExtra}</div>
            )}
          </div>
        )}

        <div className={contentClassName}>{children}</div>
      </div>
    </div>
  )
}

export default CustomCard

const CaretUpIcon = ({ color, size }: { color: string; size: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={size}
      height={size}
    >
      <rect width="256" height="256" fill="none" />
      <polyline
        points="48 160 128 80 208 160"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  )
}

export default CaretUpIcon

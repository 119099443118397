import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'

const getLeads = async () => {
  const result = await apiRequest({
    type: RequestType.GET,
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/leads`,
  })

  return result
}

export default getLeads

const LockOpenedIcon = ({ color, size }: { color: string; size: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      height={size}
      width={size}
      fill={color}
    >
      <rect width="256" height="256" fill="none" />
      <rect
        x="40"
        y="88"
        width="176"
        height="128"
        rx="8"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <circle cx="128" cy="152" r="12" />
      <path
        d="M88,88V56a40,40,0,0,1,40-40c19.35,0,36.29,13.74,40,32"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  )
}

export default LockOpenedIcon

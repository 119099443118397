import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import Button from 'components/commons/Button'

const ErrorView = ({
  message,
  buttonMessage,
  onNavigateButtonClickPath,
}: {
  message: string
  buttonMessage: string
  onNavigateButtonClickPath: string
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-lightWind">
      <h1 className="mb-4 text-2xl font-bold">{t('error')}</h1>
      <p className="mb-4 text-lg">{message}</p>
      <Button
        style="primary"
        onClick={() => navigate({ to: onNavigateButtonClickPath })}
      >
        {buttonMessage}
      </Button>
    </div>
  )
}

export default ErrorView

import { useTranslation } from 'react-i18next'

import LeadCard from 'components/commons/LeadCard/LeadCard'
import EmptyIcon from 'components/SVG/Icons/EmptyIcon'
import { useProjects } from 'hooks/useProjects'
import { Project } from 'types'

import theme from 'styles/theme'

const ProjectList = ({ projects }: { projects: Project[] }) => {
  const { t } = useTranslation()
  const { getProjectLikeStatus } = useProjects()

  return (
    <div className="flex w-full flex-col gap-5">
      {!!projects.length && (
        <div className="text-sm font-bold">
          <div className="text-sm font-bold lowercase">{`${projects.length} ${projects?.length > 1 ? t('commons.projects') : t('commons.project')}`}</div>
        </div>
      )}
      <div className="flex flex-wrap gap-5">
        {!!projects.length &&
          projects.map((project) => (
            <div
              key={project.uuid}
              className="w-full min-w-[300px] sm:w-[calc(50%-10px)] lg:w-[calc(33.333%-13.333px)]"
            >
              <LeadCard
                isLiked={getProjectLikeStatus(project.uuid)}
                project={project}
              />
            </div>
          ))}

        {projects.length === 0 && (
          <div className="flex h-[300px] w-full flex-col items-center justify-center gap-3">
            <EmptyIcon color={theme.colors.strongWind} size={40} />
            <div className="text-xl text-strongWind">
              {t('commons.noResult')}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProjectList

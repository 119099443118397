import { useForm, useController, SubmitHandler } from 'react-hook-form'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Form, Input, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'

import sendGACustomEvent from 'utils/gaCustomEventSender'
import Button from 'components/commons/Button'
import {
  GAEventAction,
  GAEventContext,
  GAEventElement,
  PROJECT_LIKE_STATUS,
  User,
} from 'types'

const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY
const EMAILJS_FEEDBACK_TEMPLATE_ID =
  process.env.REACT_APP_EMAILJS_FEEDBACK_TEMPLATE_ID
const EMAILJS_ERROR_TEMPLATE_ID =
  process.env.REACT_APP_EMAILJS_ERROR_TEMPLATE_ID
const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID
const EMAILJS_FEEDBACK_TO_EMAIL =
  process.env.REACT_APP_EMAILJS_FEEDBACK_TO_EMAIL
const EMAILJS_SUPPORT_TO_EMAIL = process.env.REACT_APP_EMAILJS_SUPPORT_TO_EMAIL

type Inputs = {
  question1: string
  question2: string
  question3: string
}

const FeedbackForm = ({
  projectUuid,
  choosenFeedback,
  updateProjectStatus,
}: {
  projectUuid: string
  choosenFeedback: PROJECT_LIKE_STATUS | null
  updateProjectStatus: (type: PROJECT_LIKE_STATUS) => void
}) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => {
      const userData = queryClient.getQueryData<User>(['user'])

      return userData || null
    },
  })

  useEffect(() => {
    emailjs.init({ publicKey: EMAILJS_PUBLIC_KEY })
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const { field: question1Field } = useController({
    name: 'question1',
    control,
    rules: { required: t('errors.fieldRequired') },
    defaultValue: '',
  })

  const { field: question2Field } = useController({
    name: 'question2',
    control,
    defaultValue: '',
  })

  const { field: question3Field } = useController({
    name: 'question3',
    control,
    rules: { required: t('errors.fieldRequired') },
    defaultValue: '',
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.FEEDBACK_FORM,
      action: GAEventAction.SUBMIT,
    })

    setIsFormSubmitting(true)

    if (choosenFeedback) {
      try {
        if (
          EMAILJS_PUBLIC_KEY &&
          EMAILJS_SERVICE_ID &&
          EMAILJS_FEEDBACK_TEMPLATE_ID
        ) {
          await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_FEEDBACK_TEMPLATE_ID, {
            projectUuid,
            projectLink: window.location.href,
            status:
              choosenFeedback === PROJECT_LIKE_STATUS.LIKED
                ? `GO ✅`
                : 'NO GO ❌',
            question1: data.question1,
            question2: data.question2,
            question3: data.question3,
            to_email: EMAILJS_FEEDBACK_TO_EMAIL,
            from_user_displayName:
              user?.displayName || 'No user name available',
          })
        }

        updateProjectStatus(choosenFeedback)

        notification.success({
          message: t(
            'views.developer.dashboard.lead.feedbackForm.feedbackSubmittedMessage'
          ),
          description: t(
            'views.developer.dashboard.lead.feedbackForm.feedbackSubmittedDescription'
          ),
          placement: 'topRight',
          duration: 5,
        })

        setIsFormSubmitting(false)
      } catch (error) {
        console.error('Error:', error)
        notification.error({
          message: t('errors.error'),
          description: t('errors.cantSubmitFeedback'),
          placement: 'topRight',
          duration: 5,
        })

        if (
          EMAILJS_PUBLIC_KEY &&
          EMAILJS_SERVICE_ID &&
          EMAILJS_ERROR_TEMPLATE_ID
        )
          await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_ERROR_TEMPLATE_ID, {
            projectUuid,
            projectLink: window.location.href,
            question1: data.question1,
            question2: data.question2,
            question3: data.question3,
            message: t('errorSubmittingFeedback'),
            to_email: EMAILJS_SUPPORT_TO_EMAIL,
            from_user_displayName:
              user?.displayName || 'No user name available',
          })

        setIsFormSubmitting(false)
      }
    }
  }

  const RequiredMark = () => (
    <span className="ml-1 text-right text-xs text-coquelicot">*</span>
  )

  const { TextArea } = Input

  return (
    <Form
      onFinish={handleSubmit(onSubmit)}
      layout="vertical"
      className="mt-5 flex flex-col gap-5 border-0 border-t-[1px] border-solid border-strongWind pt-5"
    >
      <Form.Item
        className="mb-0"
        validateStatus={errors.question1 ? 'error' : ''}
        help={errors.question1?.message?.toString()}
        label={
          <label
            htmlFor="question1"
            className="font-secondary text-xs uppercase"
          >
            {t(
              'views.developer.dashboard.lead.feedbackForm.shareVisionRisksQuestion'
            )}
            <RequiredMark />
          </label>
        }
      >
        <TextArea
          {...question1Field}
          placeholder={t('commons.enterYourAnswer')}
          className="input"
        />
      </Form.Item>

      <Form.Item
        className="mb-0"
        label={
          <label
            htmlFor="question2"
            className="font-secondary text-xs uppercase"
          >
            {t(
              'views.developer.dashboard.lead.feedbackForm.reasonForDisagreementQuestion'
            )}
          </label>
        }
      >
        <TextArea
          {...question2Field}
          placeholder={t('commons.enterYourAnswer')}
          className="input"
        />
      </Form.Item>

      <Form.Item
        className="mb-0"
        validateStatus={errors.question3 ? 'error' : ''}
        help={errors.question3?.message?.toString()}
        label={
          <label
            htmlFor="question3"
            className="font-secondary text-xs uppercase"
          >
            {t(
              'views.developer.dashboard.lead.feedbackForm.additionalCriteriaQuestion'
            )}
            <RequiredMark />
          </label>
        }
      >
        <TextArea
          {...question3Field}
          placeholder={t('commons.enterYourAnswer')}
          className="input"
        />
      </Form.Item>
      <Form.Item className="mb-0 w-full">
        <div className="flex w-full items-center justify-between">
          <Button
            htmlType="submit"
            style={isFormSubmitting ? 'disabled' : 'secondary'}
            loading={isFormSubmitting}
          >
            {t('views.developer.dashboard.lead.feedbackForm.submitFeedback')}
          </Button>

          <div className="flex items-center">
            <RequiredMark />
            <span className="ml-1 text-sm">{t('commons.requiredField')}</span>
          </div>
        </div>
      </Form.Item>
    </Form>
  )
}

export default FeedbackForm

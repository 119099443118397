import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import Sidebar from 'views/developer/dashboard/components/Sidebar'
import BurgerMenuIcon from 'components/SVG/Icons/BurgerMenuIcon'

const MobileMenu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const { t } = useTranslation()

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  const CLOSE_BUTTON_TEXT_SIZE = 'text-xl'

  return (
    <>
      <div className="lg:hidden">
        <button onClick={toggleMobileMenu} className="p-2">
          {!isMobileMenuOpen && <BurgerMenuIcon color="black" size={25} />}
          {isMobileMenuOpen && (
            <div className={`text-transparent ${CLOSE_BUTTON_TEXT_SIZE}`}>
              {t('commons.close')}
            </div>
          )}
        </button>
      </div>

      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 z-40 bg-black bg-opacity-30 lg:hidden"
          onClick={toggleMobileMenu}
        >
          <div
            className="absolute right-7 top-[35px] flex flex-col items-end"
            onClick={(e) => e.stopPropagation()}
          >
            <span
              className={`mb-8 text-right text-white hover:cursor-pointer ${CLOSE_BUTTON_TEXT_SIZE}`}
              onClick={toggleMobileMenu}
            >
              {t('commons.close')}
            </span>

            <Sidebar isMobileMenu={true} />
          </div>
        </div>
      )}
    </>
  )
}

export default MobileMenu

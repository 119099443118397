import i18n from 'utils/i18n'
import axios from 'axios'

import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'

interface Credentials {
  email: string
  password: string
}

const loginUser = async (credentials: Credentials) => {
  try {
    const result = await apiRequest({
      type: RequestType.POST,
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/login`,
      payload: credentials,
    })

    return result
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        throw new Error(i18n.t('errors.loginWrongCredentials'))
      } else {
        throw new Error(
          error.response?.data?.message || i18n.t('errors.unexpectedError')
        )
      }
    } else {
      throw new Error(i18n.t('errors.unexpectedError'))
    }
  }
}

export default loginUser

import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'

import deleteBrowserUserSession from 'utils/deleteBrowserUserSession'
import { GAEventAction, GAEventContext, GAEventElement } from 'types'
import SignOutIcon from 'components/SVG/Icons/SignOutIcon'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import logoutUser from 'actions/logout-user'

interface User {
  id: number
  email: string
  displayName: string
}

const LogoutSection = ({ isExpanded }: { isExpanded: boolean }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => {
      const userData = queryClient.getQueryData<User>(['user'])

      return userData || null
    },
  })

  const logoutMutation = useMutation({
    mutationFn: logoutUser,
    onSuccess: () => {
      deleteBrowserUserSession({ queryClient })
      navigate({ to: '/' })
    },
    onError: () => {
      notification.error({
        message: t('errors.error'),
        description: t('errors.unableToLogOut'),
      })
    },
  })

  const handleLogOut = () => {
    sendGACustomEvent({
      context: GAEventContext.LOGOUT,
      element: GAEventElement.BUTTON,
      action: GAEventAction.CLICK,
    })

    logoutMutation.mutate()
  }

  if (!user) {
    return null
  }

  return (
    <div
      className={`flex items-center justify-between rounded-2 bg-lightWind ${isExpanded && 'p-3'} overflow-hidden`}
    >
      {isExpanded && user && (
        <div className="text-xs font-bold">{user.displayName}</div>
      )}
      <div
        className={`flex items-center justify-center gap-2 ${isExpanded && 'rounded-1'} ${!isExpanded && 'flex-1'} p-2 text-sm hover:cursor-pointer ${!isExpanded && 'hover:bg-wind'}`}
        onClick={handleLogOut}
      >
        {isExpanded && (
          <div className="text-nowrap text-xs">{t('commons.logOut')}</div>
        )}
        <div className="flex items-center justify-center">
          <SignOutIcon size={isExpanded ? 20 : 25} color={'black'} />
        </div>
      </div>
    </div>
  )
}

export default LogoutSection

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import Button from 'components/commons/Button'
import { User } from 'types'

const PlaceholderProjectsList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const projects = new Array(6).fill(null)

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => {
      const userData = queryClient.getQueryData<User>(['user'])

      return userData || null
    },
  })

  const onLoginButtonClick = () =>
    navigate({ to: user ? '/developer/dashboard' : '/login' })

  return (
    <>
      <div className="relative flex flex-wrap justify-center gap-5">
        <div
          className="absolute z-20 flex w-[90%] max-w-[350px] flex-col items-center justify-center rounded-2 bg-soil p-10 drop-shadow-lg"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <span className="block pb-2 text-2xl font-bold text-white">
            {t('views.home.placeholderProjectsList.accessLocked')}
          </span>

          <span className="block pb-10 text-center text-white">
            {t('views.home.placeholderProjectsList.loginToViewProjects')}
          </span>

          <Button onClick={onLoginButtonClick}>
            {user ? t('commons.accessMyDasbhoard') : t('commons.logIn')}
          </Button>
        </div>
        <div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent to-wind" />
        <div className="absolute inset-0" />

        {projects.slice(0, 2).map((_, i) => (
          <img
            key={i}
            src={`/assets/images/placeholders_leads/${i}.png`}
            alt={t('views.home.placeholderProjectsList.projectImageAltTag')}
            style={{ objectFit: 'cover', width: '400px', height: '545px' }}
            draggable="false"
          />
        ))}

        {projects.slice(2, 4).map((_, i) => (
          <img
            key={i + 2}
            src={`/assets/images/placeholders_leads/${i + 2}.png`}
            alt={t('views.home.placeholderProjectsList.projectImageAltTag')}
            style={{ objectFit: 'cover', width: '400px', height: '545px' }}
            draggable="false"
            className="hidden lg:block"
          />
        ))}

        {projects.slice(4, 6).map((_, i) => (
          <img
            key={i + 4}
            src={`/assets/images/placeholders_leads/${i + 4}.png`}
            alt={t('views.home.placeholderProjectsList.projectImageAltTag')}
            style={{ objectFit: 'cover', width: '400px', height: '545px' }}
            draggable="false"
            className="hidden xl:block"
          />
        ))}
      </div>
    </>
  )
}

export default PlaceholderProjectsList

import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { Select } from 'antd'

import { GAEventAction, GAEventContext, GAEventElement } from 'types'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import FilterIcon from 'components/SVG/Icons/FilterIcon'
import useSpecifications from 'hooks/useSpecifications'
import CloseIcon from 'components/SVG/Icons/CloseIcon'

import theme from 'styles/theme'

export interface FiltersType {
  specification?: string
  review?: string
}

const Filters = ({
  filters,
  setFilters,
}: {
  filters: FiltersType
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>
}) => {
  const { t } = useTranslation()
  const { specifications } = useSpecifications()

  const specificationOptions = useMemo(
    () =>
      specifications?.map((spec) => ({
        value: spec.key,
        label: spec.title,
      })) || [],
    [specifications]
  )

  const reviewOptions = [
    {
      value: 'liked',
      label: t('commons.liked'),
    },
    {
      value: 'disliked',
      label: t('commons.disliked'),
    },
    {
      value: 'pending',
      label: t('commons.pending'),
    },
  ]

  const clearIcon = (
    <div className="-mt-[1px]">
      <CloseIcon size={15} color="black" />
    </div>
  )

  const handleSpecificationChange = (value: string | undefined) => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.FILTER,
      action: GAEventAction.CHANGE_SPECIFICATION,
    })

    setFilters((prev) => ({ ...prev, specification: value }))
  }

  const handleReviewChange = (value: string | undefined) => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.FILTER,
      action: GAEventAction.CHANGE_REVIEW,
    })
    setFilters((prev) => ({ ...prev, review: value }))
  }

  return (
    <div className="flex items-center justify-end gap-2">
      <FilterIcon size={20} color={theme.colors.strongWind} />
      <Select
        style={{ minWidth: 140, height: 41 }}
        onChange={handleSpecificationChange}
        value={filters.specification}
        placeholder={t('commons.specification')}
        options={specificationOptions}
        allowClear={{ clearIcon }}
        popupMatchSelectWidth={false}
      />
      <Select
        style={{ width: 150, height: 41 }}
        onChange={handleReviewChange}
        value={filters.review}
        placeholder={t('commons.review')}
        options={reviewOptions}
        allowClear={{ clearIcon }}
      />
    </div>
  )
}

export default Filters

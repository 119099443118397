import { useState } from 'react'

import LeadPageRightPart from 'views/developer/dashboard/lead/components/LeadPageRightPart'
import LeadPageLeftPart from 'views/developer/dashboard/lead/components/LeadPageLeftPart'
import { useProjectStatus } from 'hooks/useProjectData'
import { Project } from 'types'

const LeadPageContent = ({
  project,
  projectUuid,
}: {
  project: Project
  projectUuid: string
}) => {
  const [picturePath, setPicturePath] = useState(
    project.mainMapPicturePath || ''
  )
  const { projectStatus, updateProjectStatus } = useProjectStatus(projectUuid)

  const handleChangePicturePath = (path?: string) => {
    setPicturePath(path || project.mainMapPicturePath || '')
  }

  return (
    <div id="main-container" className="flex w-full flex-col gap-4 lg:flex-row">
      <LeadPageLeftPart
        project={project}
        picturePath={picturePath}
        projectStatus={projectStatus}
      />
      <LeadPageRightPart
        project={project}
        picturePath={picturePath}
        handleChangePicturePath={handleChangePicturePath}
        projectUuid={projectUuid}
        updateProjectStatus={updateProjectStatus}
      />
    </div>
  )
}

export default LeadPageContent

import { ReactNode } from 'react'

const SectionItem = ({
  icon,
  title,
  description,
}: {
  icon: ReactNode | string | number
  title: string
  description: string
}) => {
  return (
    <div className="flex flex-1 flex-col items-start gap-3 md:flex-row md:gap-10">
      <div>
        <div className="flex h-[80px] w-[80px] items-center justify-center rounded-[80px] bg-corn font-secondary text-[30px]">
          {icon}
        </div>
      </div>
      <div>
        <div className="text-2xl font-bold">{title}</div>
        <div>{description}</div>
      </div>
    </div>
  )
}

export default SectionItem

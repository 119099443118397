import { QueryClient } from '@tanstack/react-query'
import { notification } from 'antd'
import { AxiosError } from 'axios'

import deleteBrowserUserSession from 'utils/deleteBrowserUserSession'
import { queryClient } from 'components/commons/QueryClientProvider'
import getUserBySession from 'actions/get-user-by-session'
import { router } from 'components/commons/Router'
import i18n from 'utils/i18n'

import { User } from 'types'

const deleteUserAndRedirect = (queryClient: QueryClient) => {
  deleteBrowserUserSession({ queryClient })
  router.navigate({ to: '/login' })
}

const handleLogoutAndNotify = (queryClient: QueryClient) => {
  notification.error({
    message: i18n.t('errors.sessionEnded'),
    description: i18n.t('errors.disconnectedSecurityReason'),
  })
  deleteUserAndRedirect(queryClient)
}

const checkUserSession = async () => {
  try {
    const user = await queryClient.getQueryData(['user'])

    if (user) {
      const fetchedUser = await queryClient.fetchQuery<User | null>({
        queryKey: ['user'],
        queryFn: getUserBySession,
      })

      if (fetchedUser) return fetchedUser
      else handleLogoutAndNotify(queryClient)
    } else {
      deleteUserAndRedirect(queryClient)
    }
  } catch (error) {
    const axiosError = error as AxiosError

    if (axiosError.response?.status === 401) {
      handleLogoutAndNotify(queryClient)
    }
  }
}

export default checkUserSession

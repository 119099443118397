import { ReactNode } from 'react'

const HighlightedText = ({
  text,
  textColor,
  backgroundColor,
}: {
  text: string | number | ReactNode
  textColor?: string
  backgroundColor?: string
}) => {
  return (
    <div
      className={`${backgroundColor || 'bg-corn'} inline-block px-[0.2em] ${textColor || 'text-soil'}`}
    >
      {text}
    </div>
  )
}

export default HighlightedText

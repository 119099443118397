import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'

const getSpecifications = async () => {
  const result = await apiRequest({
    type: RequestType.GET,
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/specifications`,
  })

  return result
}

export default getSpecifications

import { ReactNode } from 'react'

const HomeDataCard = ({
  title,
  description,
  backgroundImagePath,
}: {
  title?: string | number | ReactNode
  description?: string | number | ReactNode
  backgroundImagePath: string
}) => {
  return (
    <div className="relative min-w-[300px] flex-1 overflow-hidden rounded-2 p-10 text-white drop-shadow-lg transition-hover-up">
      <div className="absolute inset-0">
        <img
          src={backgroundImagePath}
          alt="Field for photovoltaic project"
          className="h-full w-full object-cover"
        />
      </div>
      <div className="absolute inset-0 bg-black opacity-60"></div>
      {title && (
        <div className="relative z-10 pb-2 text-2xl font-bold">{title}</div>
      )}
      {description && <div className="relative z-10 mt-5">{description}</div>}
    </div>
  )
}

export default HomeDataCard

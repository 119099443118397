import CryptoJS from 'crypto-js'

const REACT_APP_CRYPTO_SECRET_KEY = process.env.REACT_APP_CRYPTO_SECRET_KEY
const NODE_ENV = process.env.NODE_ENV

export const encrypt = (data: string) => {
  if (!REACT_APP_CRYPTO_SECRET_KEY || !NODE_ENV || NODE_ENV === 'development')
    return data

  return CryptoJS.AES.encrypt(data, REACT_APP_CRYPTO_SECRET_KEY!).toString()
}

export const decrypt = (ciphertext: string) => {
  if (!REACT_APP_CRYPTO_SECRET_KEY || !NODE_ENV || NODE_ENV === 'development')
    return ciphertext

  const bytes = CryptoJS.AES.decrypt(ciphertext, REACT_APP_CRYPTO_SECRET_KEY!)

  return bytes.toString(CryptoJS.enc.Utf8)
}

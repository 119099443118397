import { ReactNode } from 'react'

const HomeSectionTitle = ({
  title,
  color,
}: {
  title: ReactNode
  color?: string
}) => {
  return (
    <div
      className={`mb-[30px] mt-[120px] flex flex-1 justify-center font-secondary uppercase ${color || 'text-soil'}`}
    >
      {title}
    </div>
  )
}

export default HomeSectionTitle

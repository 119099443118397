const theme = {
  colors: {
    lightWind: '#F9FAFA',
    wind: '#EBEDED',
    strongWind: '#CACACA',

    water: '#C5E1FF',
    darkWater: '#568ac2',

    lightCorn: '#FBFFC9',
    corn: '#F1FF33',

    lightSoil: '#BDB5B4',
    soil: '#260806',

    lightGrass: '#D4F9E0',
    grass: '#61EC98',
    strongGrass: '#013C19',

    sun: '#FFD700',
    strongSun: '#3E3C30',

    coquelicot: '#F9201D',

    sand: '#FCF9EC',
  },
  spacing: {
    0: '0px',
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '36px',
    10: '40px',
    11: '44px',
    12: '48px',
    14: '56px',
    16: '64px',
  },
  borderRadius: {
    1: '5px',
    2: '10px',
    3: '15px',
  },
}

export default theme

import { ReactNode, useEffect } from 'react'
import ReactGA from 'react-ga4'

import ErrorDisplay from 'components/commons/ErrorDisplay'
import Breadcrumb from 'components/commons/BreadCrumb'
import Loader from 'components/commons/Loader'

const DeveloperDashboardPageContent = ({
  children,
  analyticsPageTitle,
  childrenExtra,
  breadcrumb = true,
  isLoading,
  error,
  errorTitle,
  errorMessage,
  errorIcon,
}: {
  children: ReactNode
  analyticsPageTitle: string
  childrenExtra?: ReactNode
  breadcrumb?: boolean
  isLoading?: boolean
  error?: Error | boolean | null
  errorTitle?: string
  errorMessage?: string
  errorIcon?: ({ size, color }: { size: number; color: string }) => JSX.Element
}) => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      title: analyticsPageTitle,
    })

    window?.scrollTo(0, 0)
  }, [analyticsPageTitle])

  return (
    <div className="relative flex min-h-[100%] w-full gap-10">
      <div className="flex flex-1 flex-col gap-10">
        {(childrenExtra || breadcrumb) && (
          <div className="flex flex-wrap items-center justify-between gap-10 md:gap-5">
            {breadcrumb && (
              <div>
                <Breadcrumb />
              </div>
            )}
            {childrenExtra && <div>{childrenExtra}</div>}
          </div>
        )}

        {isLoading && <Loader />}

        {!isLoading && (
          <div className="relative flex h-[100%] flex-col justify-start bg-wind">
            <div className="flex flex-1 flex-col items-center gap-5 pb-20">
              {!error && children}
              {error && (
                <ErrorDisplay
                  title={errorTitle}
                  message={errorMessage}
                  error={error}
                  icon={errorIcon}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DeveloperDashboardPageContent

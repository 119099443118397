import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import it from 'translations/it.json'
import en from 'translations/en.json'

export const FALLBACK_LANGUAGE = 'en'

const resources = {
  it,
  en,
}

const getInitialLanguage = () => {
  const savedLanguage = localStorage.getItem('i18nextLng')
  return savedLanguage || FALLBACK_LANGUAGE
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: FALLBACK_LANGUAGE,
    lng: getInitialLanguage(),
    detection: {
      order: ['navigator'],
    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n

const Logo = ({
  width,
  height,
  color = '#000',
}: {
  width?: string
  height?: string
  color?: string
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 109 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Logo Inicio</title>
      <path
        d="M78.5939 0.857178H75.5129C75.1081 0.857178 74.7793 1.18594 74.7793 1.59079V22.4444C74.7793 22.8302 75.0918 23.1454 75.4803 23.1454H78.5939C78.9797 23.1454 79.2949 22.833 79.2949 22.4444V1.5609C79.2949 1.16964 78.9824 0.857178 78.5939 0.857178Z"
        fill={color}
      />
      <path
        d="M41.0265 0.857178H37.9455C37.5325 0.857178 37.2119 1.14247 37.2119 1.55818V22.4417C37.2119 22.8547 37.5298 23.1427 37.9455 23.1427H41.0265C41.4395 23.1427 41.7275 22.8574 41.7275 22.4417V1.55818C41.7275 1.14247 41.4395 0.857178 41.0265 0.857178Z"
        fill={color}
      />
      <path
        d="M3.81462 0.857178H0.73358C0.320602 0.857178 0 1.14247 0 1.55818V22.4417C0 22.8547 0.317885 23.1427 0.73358 23.1427H3.81462C4.22759 23.1427 4.51559 22.8574 4.51559 22.4417V1.55818C4.51559 1.14247 4.23031 0.857178 3.81462 0.857178Z"
        fill={color}
      />
      <path
        d="M96.9788 0.316406C90.5261 0.316406 85.2959 5.5468 85.2959 11.9999C85.2959 18.453 90.5261 23.6833 96.9788 23.6833C99.6007 23.6833 102.019 22.8193 103.97 21.363C105.018 20.5777 105.931 19.6213 106.668 18.5318C107.928 16.6678 108.662 14.4181 108.662 11.9999C108.662 5.5468 103.432 0.316406 96.9788 0.316406ZM102.926 16.0103C102.475 16.6787 101.916 17.2656 101.269 17.7492C100.071 18.6432 98.5873 19.1757 96.9761 19.1757C93.0148 19.1757 89.8033 15.9641 89.8033 12.0026C89.8033 8.04108 93.0148 4.82949 96.9761 4.82949C100.937 4.82949 104.149 8.04108 104.149 12.0026C104.152 13.4861 103.701 14.8664 102.926 16.0103Z"
        fill={color}
      />
      <path
        d="M29.7179 0.857178H26.6369C26.2321 0.857178 25.9033 1.18594 25.9033 1.59079V15.0757C25.9033 15.3284 25.5718 15.4208 25.4414 15.2061L16.9319 1.2593C16.7797 1.01205 16.5108 0.859889 16.2228 0.859889H13.6362H12.006C11.6012 0.859889 11.2725 1.18866 11.2725 1.59351V22.4145C11.2725 22.8194 11.6012 23.1482 12.006 23.1482H15.0871C15.4729 23.1482 15.7881 22.8357 15.7881 22.4471V8.94051C15.7881 8.68782 16.1195 8.59544 16.2499 8.81009L24.754 22.7487C24.9062 22.996 25.1724 23.1482 25.4632 23.1482H28.0361H29.7179C30.1037 23.1482 30.4189 22.8357 30.4189 22.4471V1.56362C30.4189 1.16964 30.1037 0.857178 29.7179 0.857178Z"
        fill={color}
      />
      <path
        d="M63.2044 17.7491C61.8894 18.7219 60.2375 19.2734 58.4524 19.1593C54.8606 18.9338 51.9426 15.9912 51.7497 12.3965C51.5269 8.25564 54.8199 4.82668 58.9116 4.82668C61.2618 4.82668 63.3457 5.95699 64.6553 7.70136C64.8944 8.01926 65.3372 8.09262 65.6524 7.85079L68.1167 5.96514C68.421 5.73147 68.4835 5.29401 68.2525 4.98698C66.0165 2.01177 62.3947 0.139706 58.3465 0.329902C52.4806 0.607045 47.6661 5.31032 47.2613 11.1684C46.7885 17.9964 52.1898 23.6833 58.917 23.6833C61.5389 23.6833 63.957 22.8192 65.9078 21.3629C66.7989 20.6945 67.595 19.9038 68.2634 19.0126C68.4943 18.7028 68.4346 18.2654 68.1303 18.0317L65.666 16.1433C65.359 15.9097 64.9188 15.964 64.6879 16.271C64.3645 16.6868 63.8646 17.2601 63.2044 17.7491Z"
        fill={color}
      />
    </svg>
  )
}

export default Logo

const BugIcon = ({ color, size }: { color: string; size: string | number }) => {
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="none" />
      <circle cx="156" cy="92" r="12" fill={color} />
      <circle cx="100" cy="92" r="12" fill={color} />
      <line
        x1="128"
        y1="128"
        x2="128"
        y2="224"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M208,144a80,80,0,0,1-160,0V112a80,80,0,0,1,160,0Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        x1="232"
        y1="184"
        x2="203.18"
        y2="171.41"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        x1="232"
        y1="72"
        x2="203.18"
        y2="84.59"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        x1="24"
        y1="72"
        x2="52.82"
        y2="84.59"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        x1="24"
        y1="184"
        x2="52.82"
        y2="171.41"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        x1="16"
        y1="128"
        x2="240"
        y2="128"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  )
}

export default BugIcon

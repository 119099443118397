export enum ENVIRONMENT_TYPE {
  PRODUCTION = 'production',
  STAGING = 'staging',
  LOCAL = 'local',
}

export enum CRITERON_TYPE {
  WARNING = 'warning',
  OK = 'ok',
  NOT_OK = 'nok',
}

export enum PROJECT_LIKE_STATUS {
  LIKED = 'liked',
  DISLIKED = 'disliked',
}

export type ProjectInLocalStorage = {
  id: number
  isViewable: boolean
  isLiked?: PROJECT_LIKE_STATUS
}

export type SideMenuItem = {
  title: string
  type?: 'category' | 'item' | 'dropdown'
  children?: SideMenuItem[]
  key?: number
  icon?: ({ size, color }: { size: number; color: string }) => JSX.Element
  path?: string
}

export interface SideMenuCategory {
  title: string
  type?: 'category' | 'item' | 'dropdown'
  children?: SideMenuItem[]
}

export type User = {
  uuid: string
  displayName: string
  email: string
}

export type Criterion = {
  type: string
  title: string
  description?: string
  value?: string
  items?: Criterion[]
  criterionMapPicturePath?: string
}

export type Specification = {
  id: number
  title: string
  key: string
  data: Category[]
}

export interface Score {
  key: string
  title: string
  value: number
}

export interface CriterionData {
  key?: string
  title: string
  description?: string
  type: 'ok' | 'nok' | 'warning'
  value?: string
  items?: CriterionData[]
  criterionMapPicturePath?: string
  children?: CriterionData[]
}

export interface Category {
  title: string
  description: string
  criteria: CriterionData[]
}

export interface Project {
  // TODO
  // Remove id when all clients' local storage
  // have been updated with uuid
  id?: number
  uuid: string
  mainMapPicturePath: string
  specification: string
  zone: string
  region: string
  province: string
  city: string
  area: string
  scores: Score[]
  infoMemo?: string
  data: Category[]
}

export enum LogType {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  REQUEST = 'request',
}

export enum RequestType {
  GET = 'GET',
  POST = 'POST',
}
export enum GAEventContext {
  BREADCRUMB = 'breadcrumb',
  LANGUAGES = 'languages',
  LEAD = 'lead',
  LOGIN = 'login',
  LOGOUT = 'logout',
  SIDEBAR = 'sidebar',
  SPECIFICATION = 'specification',
}

export enum GAEventElement {
  BUTTON = 'button',
  CRITERIA_CATEGORY = 'criteria_category',
  DROPDOWN = 'dropdown',
  FEEDBACK_BUTTON = 'feedback_button',
  FEEDBACK_FORM = 'feedback_form',
  FILTER = 'filter',
  FORM = 'form',
  IMAGE = 'image',
  INFO_MEMO = 'info_memo',
  ITEM = 'item',
  KML = 'kml',
  MENU = 'menu',
  POPOVER = 'popover',
}

export enum GAEventAction {
  CHANGE = 'change',
  CHANGE_REVIEW = 'change_review',
  CHANGE_SPECIFICATION = 'change_specification',
  CLICK = 'click',
  CLICK_NOT_POSITIVE = 'click_not_positive',
  CLICK_POSITIVE = 'click_positive',
  CLOSE = 'close',
  DOWNLOAD = 'download',
  EXPAND = 'expand',
  OPEN = 'open',
  SHRINK = 'shrink',
  SUBMIT = 'submit',
}

import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'

const getLead = async ({ uuid }: { uuid: string }) => {
  const result = await apiRequest({
    type: RequestType.GET,
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/leads/${uuid}`,
  })

  return result
}

export default getLead

import AWS from 'aws-sdk'

import { LogType } from 'types'

const LOG_GROUP_NAME: string | undefined = process.env.REACT_APP_AWS_LOG_GROUP
const LOG_STREAM_NAME: string | undefined =
  process.env.REACT_APP_AWS_STREAM_NAME

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
})

const cloudwatchlogs = new AWS.CloudWatchLogs()

const logToCloudWatch = (
  type: LogType,
  message:
    | string
    | { [key: string]: string | number | object | undefined | null }
    | Error
) => {
  if (!LOG_STREAM_NAME || !LOG_GROUP_NAME) return null

  const logMessage =
    typeof message === 'string' ? message : JSON.stringify(message)

  const params = {
    logEvents: [
      {
        message: `[${type.toUpperCase()}] ${logMessage}`,
        timestamp: Date.now(),
      },
    ],
    logGroupName: LOG_GROUP_NAME,
    logStreamName: LOG_STREAM_NAME,
  }

  cloudwatchlogs.putLogEvents(params, (err) => {
    if (err) console.error('Error logging to CloudWatch:', err)
  })
}

export default logToCloudWatch

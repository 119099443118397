import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'

const logoutUser = async () => {
  const result = await apiRequest({
    type: RequestType.POST,
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/logout`,
  })

  if (result.status === 201) return true
}

export default logoutUser

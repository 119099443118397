import { QueryClient } from '@tanstack/react-query'

const deleteBrowserUserSession = ({
  queryClient,
}: {
  queryClient: QueryClient
}) => {
  queryClient.clear()
  localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE')
}

export default deleteBrowserUserSession

import { useNavigate, useRouterState } from '@tanstack/react-router'
import React, { useEffect, useState } from 'react'
import { Popover } from 'antd'

import CaretDownIcon from 'components/SVG/Icons/CaretDownIcon'
import CaretUpIcon from 'components/SVG/Icons/CaretUpIcon'
import { SideMenuItem } from 'types'

interface MenuItemProps {
  item: SideMenuItem
  isExpanded: boolean
  shouldAnimate: boolean
}

const useDropdownState = (
  itemTitle: string
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isOpen, setIsOpen] = useState<boolean>(() => {
    const savedState = localStorage.getItem(`dropdown-${itemTitle}`)
    return savedState ? JSON.parse(savedState) : false
  })

  useEffect(() => {
    localStorage.setItem(`dropdown-${itemTitle}`, JSON.stringify(isOpen))
  }, [isOpen, itemTitle])

  return [isOpen, setIsOpen]
}

interface IconWrapperProps {
  Icon: React.ComponentType<{ size: number; color: string }> | undefined
}

const IconWrapper: React.FC<IconWrapperProps> = ({ Icon }) =>
  Icon ? <Icon size={25} color="black" /> : null

interface ExpandedTitleProps {
  title: string
  isExpanded: boolean
  shouldAnimate: boolean
}

const ExpandedTitle: React.FC<ExpandedTitleProps> = ({ title, isExpanded }) => (
  <>
    {isExpanded && (
      <div
        key="item-title"
        className="inline-block overflow-hidden whitespace-nowrap"
      >
        {title}
      </div>
    )}
  </>
)

interface DropdownCaretProps {
  isOpen: boolean
  isExpanded: boolean
}

const DropdownCaret: React.FC<DropdownCaretProps> = ({ isOpen, isExpanded }) =>
  isExpanded && (
    <div className="ml-auto">
      {isOpen ? (
        <CaretUpIcon color="black" size={20} />
      ) : (
        <CaretDownIcon color="black" size={20} />
      )}
    </div>
  )

interface DropdownItemsProps {
  item: SideMenuItem
  isExpanded: boolean
  location: { pathname: string }
  navigate: (options: { to: string }) => void
}

const DropdownItems: React.FC<DropdownItemsProps> = ({
  item,
  isExpanded,
  location,
  navigate,
}) =>
  isExpanded && (
    <ul className="flex flex-col gap-1 text-sm">
      {item.children?.map((dropdownItem) => (
        <li
          key={dropdownItem.key ?? dropdownItem.title}
          className={`flex items-center gap-2 rounded-2 p-2 text-sm hover:cursor-pointer ${
            dropdownItem.path === location.pathname ? 'bg-corn' : ''
          }`}
          onClick={() =>
            dropdownItem.path && navigate({ to: dropdownItem.path })
          }
        >
          {dropdownItem.title}
        </li>
      ))}
    </ul>
  )

export const MenuItem: React.FC<MenuItemProps> = ({
  item,
  isExpanded,
  shouldAnimate,
}) => {
  const { location } = useRouterState()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useDropdownState(item.title)

  const isActive = location.pathname === item.path
  const isChildActive = item.children?.some(
    (child) => child.path === location.pathname
  )

  const isParentActive = isActive || isChildActive

  const handleClick = () => {
    if (item.type === 'dropdown') {
      setIsOpen(!isOpen)
    } else if (item.path) {
      navigate({ to: item.path })
    }
  }

  const MenuItemContent = (
    <div
      className={`flex items-center text-sm ${
        !isExpanded && 'justify-center'
      } gap-2 rounded-2 p-2 hover:cursor-pointer ${
        (isParentActive && !isOpen && isExpanded) ||
        (isParentActive && !isExpanded)
          ? 'bg-corn'
          : ''
      }`}
      title={item.title}
      onClick={handleClick}
    >
      <IconWrapper Icon={item.icon} />

      <ExpandedTitle
        title={item.title}
        isExpanded={isExpanded}
        shouldAnimate={shouldAnimate}
      />

      {item.type === 'dropdown' && (
        <DropdownCaret isOpen={isOpen} isExpanded={isExpanded} />
      )}
    </div>
  )

  return (
    <div>
      {isExpanded ? (
        MenuItemContent
      ) : (
        <Popover
          placement="right"
          title={
            !isExpanded && item.type === 'dropdown' ? (
              <span className="font-bold">{item.title}</span>
            ) : null
          }
          content={
            !isExpanded && item.type === 'dropdown' ? (
              <DropdownItems
                item={item}
                isExpanded={true}
                location={location}
                navigate={navigate}
              />
            ) : (
              <span className="font-bold">{item.title}</span>
            )
          }
        >
          {MenuItemContent}
        </Popover>
      )}

      {isOpen && (
        <div className="pl-[35px]">
          <DropdownItems
            item={item}
            isExpanded={isExpanded}
            location={location}
            navigate={navigate}
          />
        </div>
      )}
    </div>
  )
}

export default MenuItem

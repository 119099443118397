import { useForm, useController, SubmitHandler } from 'react-hook-form'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Form, Input, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'

import CheckFatIcon from 'components/SVG/Icons/CheckFatIcon'
import Button from 'components/commons/Button'
import { User } from 'types'

import theme from 'styles/theme'

const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY
const EMAILJS_NEW_SPECIFICATION_TEMPLATE_ID =
  process.env.REACT_APP_EMAILJS_NEW_SPECIFICATION_TEMPLATE_ID
const EMAILJS_ERROR_TEMPLATE_ID =
  process.env.REACT_APP_EMAILJS_ERROR_TEMPLATE_ID
const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID
const EMAILJS_NEW_SPECIFICATION_TO_EMAIL =
  process.env.REACT_APP_EMAILJS_NEW_SPECIFICATION_TO_EMAIL
const EMAILJS_SUPPORT_TO_EMAIL = process.env.REACT_APP_EMAILJS_SUPPORT_TO_EMAIL

type Inputs = {
  question1: string
}

const NewSpecificationForm = () => {
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => {
      const userData = queryClient.getQueryData<User>(['user'])

      return userData || null
    },
  })

  useEffect(() => {
    emailjs.init({ publicKey: EMAILJS_PUBLIC_KEY })
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
  } = useForm<Inputs>()

  const { field: question1Field } = useController({
    name: 'question1',
    control,
    rules: { required: t('errors.fieldRequired') },
    defaultValue: '',
  })

  const resetFormState = () => {
    setIsFormSubmitting(false)
    setIsFormSubmitted(false)
    resetForm()
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsFormSubmitting(true)
    try {
      if (
        EMAILJS_PUBLIC_KEY &&
        EMAILJS_SERVICE_ID &&
        EMAILJS_NEW_SPECIFICATION_TEMPLATE_ID
      ) {
        await emailjs.send(
          EMAILJS_SERVICE_ID,
          EMAILJS_NEW_SPECIFICATION_TEMPLATE_ID,
          {
            question1: data.question1,
            to_email: EMAILJS_NEW_SPECIFICATION_TO_EMAIL,
            from_user_displayName:
              user?.displayName || 'No user name available',
          }
        )
      }

      setIsFormSubmitting(false)
      setIsFormSubmitted(true)
    } catch (error) {
      console.error('Error:', error)
      notification.error({
        message: t('errors.error'),
        description: t('errors.cantSubmitForm'),
        placement: 'topRight',
        duration: 5,
      })

      if (EMAILJS_PUBLIC_KEY && EMAILJS_SERVICE_ID && EMAILJS_ERROR_TEMPLATE_ID)
        await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_ERROR_TEMPLATE_ID, {
          question1: data.question1,
          message: t('errors.cantSubmitForm'),
          to_email: EMAILJS_SUPPORT_TO_EMAIL,
          from_user_displayName: user?.displayName || 'No user name available',
        })

      setIsFormSubmitting(false)
    }
  }

  const RequiredMark = () => (
    <span className="ml-1 text-right text-xs text-coquelicot">*</span>
  )

  const { TextArea } = Input

  return (
    <>
      {!isFormSubmitted ? (
        <Form
          onFinish={handleSubmit(onSubmit)}
          layout="vertical"
          className="mt-5 flex flex-col gap-5"
        >
          <Form.Item
            className="mb-0"
            validateStatus={errors.question1 ? 'error' : ''}
            help={errors.question1?.message?.toString()}
            label={
              <label
                htmlFor="question1"
                className="font-secondary text-xs uppercase"
              >
                {t(
                  'views.developer.dashboard.newSpecification.newSpecificationForm.otherProjectTypesQuestion'
                )}
                <RequiredMark />
              </label>
            }
          >
            <TextArea
              {...question1Field}
              placeholder={t('commons.enterYourAnswer')}
              style={{ height: 200 }}
              className="input"
            />
          </Form.Item>

          <Form.Item className="mb-0 w-full">
            <div className="flex w-full flex-wrap items-center justify-between gap-2">
              <Button
                htmlType="submit"
                style={isFormSubmitting ? 'disabled' : 'secondary'}
                loading={isFormSubmitting}
              >
                {t(
                  'views.developer.dashboard.newSpecification.newSpecificationForm.submitButtonMessage'
                )}
              </Button>

              <div className="flex items-center">
                <RequiredMark />
                <span className="ml-1 text-nowrap text-sm">
                  {t('commons.requiredField')}
                </span>
              </div>
            </div>
          </Form.Item>
        </Form>
      ) : (
        <div className="flex w-full flex-col items-center px-10 py-20">
          <CheckFatIcon size={50} color={theme.colors.grass} />
          <div className="mt-4 font-bold">
            {t(
              'views.developer.dashboard.newSpecification.newSpecificationForm.thanksTitle'
            )}
          </div>
          <div className="mb-10">
            {t(
              'views.developer.dashboard.newSpecification.newSpecificationForm.thanksMessage'
            )}
          </div>

          <Button onClick={resetFormState} style="secondary">
            {t(
              'views.developer.dashboard.newSpecification.newSpecificationForm.shareNewNeedsButtonMessage'
            )}{' '}
          </Button>
        </div>
      )}
    </>
  )
}

export default NewSpecificationForm

import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import useSideMenuItems from 'views/developer/dashboard/components/Sidebar/hooks/useSideMenuItems'
import { useSidebarExpansion } from 'views/developer/dashboard/hooks/useSidebarExpansion'
import { ExpandButton } from 'views/developer/dashboard/components/Sidebar/ExpandButton'
import { MenuCategory } from 'views/developer/dashboard/components/Sidebar/MenuCategory'
import LogoutSection from 'views/developer/dashboard/components/Sidebar/LogoutSection'
import { GAEventAction, GAEventContext, GAEventElement } from 'types'
import sendGACustomEvent from 'utils/gaCustomEventSender'

const Sidebar = ({ isMobileMenu = false }: { isMobileMenu?: boolean }) => {
  const { isExpanded, setIsExpanded, isLoading } = useSidebarExpansion()
  const [shouldAnimate, setShouldAnimate] = useState(false)

  const sideMenuItems = useSideMenuItems()

  const handleExpandedMenuClick = () => {
    const isNewValueMenuExpanded = !isExpanded

    sendGACustomEvent({
      context: GAEventContext.SIDEBAR,
      element: GAEventElement.MENU,
      action: isNewValueMenuExpanded
        ? GAEventAction.EXPAND
        : GAEventAction.SHRINK,
    })

    setIsExpanded(isNewValueMenuExpanded)
  }

  useEffect(() => {
    if (!isLoading) {
      setShouldAnimate(true)
    }
  }, [isLoading])

  const isMenuExpanded = isMobileMenu || isExpanded === true

  if (isLoading || isExpanded === null) {
    return (
      <div className="h-[calc(100vh-150px)] w-[60px] rounded-2 bg-white drop-shadow-sm" />
    )
  }

  return (
    <motion.div
      className="sticky top-[50px] h-[calc(100vh-190px)] rounded-2 bg-white drop-shadow-sm"
      initial={false}
      animate={{
        width: isMenuExpanded ? '280px' : 'auto',
      }}
      transition={
        shouldAnimate ? { duration: 0.3, ease: 'easeInOut' } : { duration: 0 }
      }
    >
      <div className="flex h-full flex-col justify-between overflow-hidden px-5 py-5">
        {!isMobileMenu && (
          <ExpandButton
            isExpanded={isMenuExpanded}
            onClick={handleExpandedMenuClick}
          />
        )}
        <div className="flex-1 overflow-auto">
          {sideMenuItems.map((menuItem) => (
            <MenuCategory
              key={menuItem.title}
              category={menuItem}
              isExpanded={isMenuExpanded}
              shouldAnimate={shouldAnimate}
            />
          ))}
        </div>
        <div className="mt-auto">
          <LogoutSection isExpanded={isExpanded} />
        </div>
      </div>
    </motion.div>
  )
}

export default Sidebar

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm, useController } from 'react-hook-form'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { Form, Input } from 'antd'

import { GAEventAction, GAEventContext, GAEventElement } from 'types'
import RequestAccess from 'views/login/components/RequestAccess'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import Button from 'components/commons/Button'
import Badge from 'components/commons/Badge'
import loginUser from 'actions/login-user'

import theme from 'styles/theme'

type LoginInputs = {
  email: string
  password: string
}

const LoginForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>()

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: (userData) => {
      queryClient.setQueryData(['user'], userData)
      navigate({ to: '/developer/dashboard' })
    },
  })

  const onSubmit = (data: LoginInputs) => {
    sendGACustomEvent({
      context: GAEventContext.LOGIN,
      element: GAEventElement.FORM,
      action: GAEventAction.SUBMIT,
    })
    loginMutation.mutate(data)
  }

  const { field: emailField } = useController({
    name: 'email',
    control,
    rules: {
      required: t('errors.emailRequired'),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t('errors.invalidEmail'),
      },
    },
    defaultValue: '',
  })

  const { field: passwordField } = useController({
    name: 'password',
    control,
    rules: { required: t('errors.passwordRequired') },
    defaultValue: '',
  })

  return (
    <Form
      onFinish={handleSubmit(onSubmit)}
      layout="vertical"
      className="mt-5 flex flex-col items-end gap-1"
    >
      <Form.Item
        validateStatus={errors.email ? 'error' : ''}
        className="w-full"
        help={errors.email?.message?.toString()}
        label={
          <label htmlFor="email" className="font-secondary text-xs uppercase">
            {t('views.login.loginForm.emailLabel')}
          </label>
        }
      >
        <Input
          {...emailField}
          type="email"
          placeholder={t('views.login.loginForm.emailPlaceholder')}
          className="input"
        />
      </Form.Item>

      <Form.Item
        validateStatus={errors.password ? 'error' : ''}
        className="w-full"
        help={errors.password?.message?.toString()}
        label={
          <label
            htmlFor="password"
            className="font-secondary text-xs uppercase"
          >
            {t('views.login.loginForm.passwordLabel')}
          </label>
        }
      >
        <Input.Password
          {...passwordField}
          placeholder={t('views.login.loginForm.passwordPlaceholder')}
          className="input"
        />
      </Form.Item>

      {loginMutation.isError && (
        <div className="-mt-1 mb-3 flex w-full justify-center">
          <Badge
            text={
              t(`${loginMutation.error.message}`) ||
              t('errors.cantLogInGeneric')
            }
            icon={undefined}
            backgroundColor={theme.colors.coquelicot}
            textColor="white"
          />
        </div>
      )}

      <div className="flex w-full justify-center">
        <RequestAccess />
      </div>

      <Form.Item className="mb-0 mt-10 w-full">
        <div className="flex- 1 flex items-center justify-between gap-4">
          <Button
            style={'primary'}
            className="border-2 border-solid border-wind bg-transparent hover:bg-wind"
            onClick={() => navigate({ to: '/' })}
          >
            {t('commons.backToHome')}
          </Button>
          <Button
            htmlType="submit"
            style={loginMutation.isPending ? 'disabled' : 'secondary'}
            loading={loginMutation.isPending}
          >
            {t('commons.logIn')}
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default LoginForm

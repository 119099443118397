import { useTranslation } from 'react-i18next'

import ListChecksIcon from 'components/SVG/Icons/ListChecksIcon'
import SubstractIcon from 'components/SVG/Icons/SubstractIcon'
import ListPlusIcon from 'components/SVG/Icons/ListPlusIcon'
import useSpecifications from 'hooks/useSpecifications'
import { SideMenuItem } from 'types'

const SideMenuItems = () => {
  const { t } = useTranslation()
  const { specifications } = useSpecifications()

  const sideMenuItems: SideMenuItem[] = [
    {
      title: t('commons.projects'),
      type: 'category',
      children: [
        {
          key: 1,
          title: t('commons.allProjects'),
          icon: SubstractIcon,
          path: '/developer/dashboard',
          children: [
            {
              key: 11,
              title: t('commons.project'),
              icon: SubstractIcon,
              path: '/developer/dashboard/project/:id',
            },
          ],
        },
      ],
    },
    {
      title: t('commons.specifications'),
      children: [
        {
          key: 2,
          title: t('commons.allSpecifications'),
          type: 'dropdown',
          icon: ListChecksIcon,
          children: specifications
            ? specifications.map((specification, i) => ({
                key: parseInt(`2${i + 1}`),
                title: specification.title,
                path: `/developer/dashboard/specification/${specification.id}`,
              }))
            : undefined,
        },
        {
          key: 3,
          title: t('commons.shareYourNeeds'),
          type: 'category',
          icon: ListPlusIcon,
          path: '/developer/dashboard/specification/new',
        },
      ],
    },
  ]

  return sideMenuItems
}

export default SideMenuItems

import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import FeedbackForm from 'views/developer/dashboard/lead/components/FeedbackForm'
import LockOpenedIcon from 'components/SVG/Icons/LockOpenedIcon'
import ThumbDownIcon from 'components/SVG/Icons/ThumbDownIcon'
import ThumbUpIcon from 'components/SVG/Icons/ThumbUpIcon'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import CustomCard from 'components/commons/CustomCard'
import {
  GAEventAction,
  GAEventContext,
  GAEventElement,
  PROJECT_LIKE_STATUS,
} from 'types'

import theme from 'styles/theme'

const VoteBar = ({
  projectUuid,
  updateProjectStatus,
}: {
  projectUuid: string
  updateProjectStatus: (type: PROJECT_LIKE_STATUS) => void
}) => {
  const { t } = useTranslation()
  const [choosenFeedback, setChoosenFeedback] =
    useState<PROJECT_LIKE_STATUS | null>(null)

  const handleChooseFeedback = (value: PROJECT_LIKE_STATUS) => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.FEEDBACK_BUTTON,
      action: PROJECT_LIKE_STATUS.LIKED
        ? GAEventAction.CLICK_POSITIVE
        : GAEventAction.CLICK_NOT_POSITIVE,
    })

    if (value === choosenFeedback) setChoosenFeedback(null)
    else setChoosenFeedback(value)
  }

  return (
    <CustomCard bgColor="bg-gradient-water-white">
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-1 items-center justify-center gap-4 text-center">
          <div className="w-[20px]">
            <LockOpenedIcon color="black" size={20} />
          </div>
          {t('views.developer.dashboard.lead.voteBar.provideYourFeedback')}
        </div>

        <div className="flex gap-2">
          <div
            onClick={() => handleChooseFeedback(PROJECT_LIKE_STATUS.LIKED)}
            className={`right-2 top-2 flex h-[40px] w-[40px] items-center justify-center rounded-full ${
              choosenFeedback && choosenFeedback !== PROJECT_LIKE_STATUS.LIKED
                ? 'bg-wind'
                : 'bg-grass'
            } bg-blend-normal hover:cursor-pointer hover:brightness-90`}
          >
            <ThumbUpIcon
              size={15}
              color={
                choosenFeedback && choosenFeedback !== PROJECT_LIKE_STATUS.LIKED
                  ? theme.colors.strongWind
                  : theme.colors.strongGrass
              }
            />
          </div>
          <div
            onClick={() => handleChooseFeedback(PROJECT_LIKE_STATUS.DISLIKED)}
            className={`right-2 top-2 flex h-[40px] w-[40px] items-center justify-center rounded-full ${
              choosenFeedback &&
              choosenFeedback !== PROJECT_LIKE_STATUS.DISLIKED
                ? 'bg-wind'
                : 'bg-coquelicot'
            } bg-blend-normal hover:cursor-pointer hover:brightness-90`}
          >
            <ThumbDownIcon
              size={15}
              color={
                choosenFeedback &&
                choosenFeedback !== PROJECT_LIKE_STATUS.DISLIKED
                  ? theme.colors.strongWind
                  : 'white'
              }
            />
          </div>
        </div>
      </div>
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          choosenFeedback ? 'max-h-[1000px]' : 'max-h-0'
        }`}
      >
        {choosenFeedback && (
          <FeedbackForm
            projectUuid={projectUuid}
            updateProjectStatus={updateProjectStatus}
            choosenFeedback={choosenFeedback}
          />
        )}
      </div>
    </CustomCard>
  )
}

export default VoteBar

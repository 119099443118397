import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import getSpecifications from 'actions/get-specifications'
import { Specification, User } from 'types'

const useSpecifications = () => {
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => {
      const userData = queryClient.getQueryData<User>(['user'])

      return userData || null
    },
  })

  const { data: specifications, error } = useQuery<Specification[]>({
    queryKey: ['specifications'],
    queryFn: getSpecifications,
    enabled: !!user,
    retry: 500,
  })

  useEffect(() => {
    const handleLanguageChange = () => {
      queryClient.invalidateQueries({ queryKey: ['specifications'] })
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [i18n, queryClient])

  return {
    specifications,
    error,
  }
}

export default useSpecifications

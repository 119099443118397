import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/react-router'
import { ReactNode } from 'react'

import LanguageSwitcher from 'components/commons/LanguageSwitcher'
import InicioTextLogo from 'components/SVG/InicioTextLogo'
import LoaderIcon from 'components/SVG/Icons/LoaderIcon'
import Footer from 'components/commons/Footer'

const PageContainer = ({
  pageHeader,
  children,
  isLoading,
  pageHeaderClassName,
  headerExtra,
}: {
  pageHeader?: ReactNode
  children: ReactNode
  isLoading?: boolean
  pageHeaderClassName?: string
  headerExtra?: ReactNode
}) => {
  const { t } = useTranslation()

  return (
    <div className="relative flex min-h-screen flex-col bg-wind">
      <div className="flex flex-1 flex-col items-center">
        <div
          className={`flex w-[90%] max-w-[1500px] items-center justify-between`}
        >
          <div className="h-fit w-fit">
            <Link to="/">
              <InicioTextLogo width="100" height="100" />
            </Link>
          </div>

          <div className="flex flex-wrap items-center justify-end gap-5">
            <LanguageSwitcher />

            {headerExtra}
          </div>
        </div>

        {isLoading ? (
          <div className="flex flex-1 flex-col items-center justify-center gap-5">
            <div>{t('commons.loading')}</div>
            <div className="animate-spin">
              <LoaderIcon color="black" size={40} />
            </div>
          </div>
        ) : (
          <>
            {!!pageHeader && pageHeader}
            <div className={`w-[90%] max-w-[1500px] ${pageHeaderClassName}`}>
              {children}
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default PageContainer

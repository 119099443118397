import { motion } from 'framer-motion'

import DoubleCaretLeftIcon from 'components/SVG/Icons/DoubleCaretLeftIcon'

export const ExpandButton = ({
  isExpanded,
  onClick,
}: {
  isExpanded: boolean
  onClick: () => void
}) => (
  <motion.button
    onClick={onClick}
    className="absolute -right-4 top-3 z-10 hidden rounded-full bg-white p-2 shadow-md md:block"
    whileHover={{ scale: 1.2 }}
    whileTap={{ scale: 0.9 }}
  >
    <motion.div
      initial={false}
      animate={{ rotate: isExpanded ? 0 : 180 }}
      transition={{ duration: 0.3 }}
    >
      <DoubleCaretLeftIcon color="black" size={15} />
    </motion.div>
  </motion.button>
)

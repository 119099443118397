import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'

import CriteriaProgressSection from 'components/commons/LeadCard/components/CriteriaProgressSection'
import ReviewStatusBadge from 'components/commons/ReviewStatusBadge'
import getFormattedNumber from 'utils/getFormattedNumber'
import CustomCard from 'components/commons/CustomCard'
import { PROJECT_LIKE_STATUS, Project } from 'types'

const LeadPageLeftPart = ({
  project,
  picturePath,
  projectStatus,
}: {
  project: Project
  picturePath: string
  projectStatus?: PROJECT_LIKE_STATUS
}) => {
  const { t } = useTranslation()

  return (
    <div
      id="left-part"
      className="flex w-full min-w-[330px] flex-col gap-4 md:flex-row lg:w-[30%] lg:flex-col"
    >
      <div
        id="map"
        className="aspect-h-1 relative aspect-square w-full overflow-hidden rounded-xl bg-black drop-shadow-lg"
      >
        <img
          key={Date.now()}
          src={`/assets/images/projects/${project.uuid}/${picturePath}`}
          alt={t(
            'views.developer.dashboard.lead.leadPageLeftPart.pictureAltTag'
          )}
          className="h-full w-full object-cover"
          style={{ objectFit: 'cover' }}
        />
      </div>

      <CustomCard containerClassName="md:w-[50%] lg:w-full">
        <div className="flex flex-col">
          <CriteriaProgressSection scores={project.scores} />

          <Divider className="my-5 border-wind" />

          <div className="flex flex-col gap-4">
            {project.zone && (
              <div className="flex flex-1 flex-col gap-1">
                <span className="font-secondary text-xs uppercase">
                  {t('commons.zone')}
                </span>
                <p>{`${project.zone}`}</p>
              </div>
            )}

            {project.region && (
              <div className="flex flex-1 flex-col gap-1">
                <span className="font-secondary text-xs uppercase">
                  {t('commons.region')}
                </span>
                <p>{`${project.region}`}</p>
              </div>
            )}

            {project.province && (
              <div className="flex flex-1 flex-col gap-1">
                <span className="font-secondary text-xs uppercase">
                  {t('commons.province')}
                </span>
                <p>{`${project.province}`}</p>
              </div>
            )}

            {project.city && (
              <div className="flex flex-1 flex-col gap-1">
                <span className="font-secondary text-xs uppercase">
                  {t('commons.city')}
                </span>
                <p>{`${project.city}`}</p>
              </div>
            )}
          </div>

          <Divider className="my-5 border-wind" />

          <div className="flex flex-1 flex-col gap-1">
            <span className="font-secondary text-xs uppercase">
              {t('views.developer.dashboard.lead.leadPageLeftPart.area')}
            </span>
            <p>{`${getFormattedNumber(parseFloat(project.area))} ha`}</p>
          </div>
        </div>

        <Divider className="my-5 border-wind" />

        <div className="flex">
          <div className="flex flex-1 flex-col gap-1">
            <span className="mb-1 font-secondary text-xs uppercase">
              {t('views.developer.dashboard.lead.leadPageLeftPart.myFeedback')}
            </span>
            <ReviewStatusBadge isLiked={projectStatus} />
          </div>
        </div>
      </CustomCard>
    </div>
  )
}

export default LeadPageLeftPart

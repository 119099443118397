import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'

const getLeadKml = async ({ uuid }: { uuid: string }) => {
  const result = await apiRequest({
    type: RequestType.GET,
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/leads/${uuid}/kml`,
  })

  return result
}

export default getLeadKml

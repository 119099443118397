import { useTranslation } from 'react-i18next'

import LockClosedIcon from 'components/SVG/Icons/LockClosedIcon'

const RestrictedWarningSection = () => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center justify-center gap-3 rounded-xl bg-soil p-5 text-white">
      <div>
        <LockClosedIcon color={'white'} size={20} />
      </div>
      <div className="flex flex-col items-center">
        <span className="text-md inline-block text-center font-bold">
          {t(
            'commonsComponents.leadCard.restrictedWarningSection.projectRestricted'
          )}
        </span>
        <span className="inline-block w-[80%] text-center text-xs">
          {t(
            'commonsComponents.leadCard.restrictedWarningSection.restrictedWarningSection'
          )}
        </span>
      </div>
    </div>
  )
}

export default RestrictedWarningSection

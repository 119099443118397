import { useTranslation } from 'react-i18next'

import LoaderIcon from 'components/SVG/Icons/LoaderIcon'

const Loader = ({ message = true }: { message?: boolean | string }) => {
  const { t } = useTranslation()

  return (
    <div className="relative flex h-[100%] flex-col justify-center bg-wind">
      <div className="flex flex-1 flex-col items-center justify-center gap-5">
        {message && <div>{message || t('commons.loading')}</div>}

        <div className="animate-spin">
          <LoaderIcon color="black" size={40} />
        </div>
      </div>
    </div>
  )
}

export default Loader

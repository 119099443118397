import { useState, useEffect } from 'react'

export const useSidebarExpansion = (initialState: boolean = true) => {
  const [isExpanded, setIsExpanded] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const saved = localStorage.getItem('sidebarExpanded')
    setIsExpanded(saved !== null ? JSON.parse(saved) : initialState)
    setIsLoading(false)
  }, [initialState])

  useEffect(() => {
    if (isExpanded !== null) {
      localStorage.setItem('sidebarExpanded', JSON.stringify(isExpanded))
    }
  }, [isExpanded])

  return {
    isExpanded,
    setIsExpanded: (
      value: boolean | ((prevState: boolean | null) => boolean)
    ) => {
      setIsExpanded(
        typeof value === 'function' ? value(isExpanded ?? initialState) : value
      )
    },
    isLoading,
  }
}

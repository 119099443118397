import ProgressCircle from 'components/commons/ProgressCircle'
import { Score } from 'types'

const PROGRESS_CIRCLE_SIZE = 60

const CriteriaProgressSection = ({ scores }: { scores: Score[] }) => {
  const criteriaCount = Object.keys(scores).length

  return (
    <div
      className="flex flex-1 justify-around"
      style={{ gridTemplateColumns: `repeat(${criteriaCount}, 1fr)` }}
    >
      {scores.map((score) => {
        return (
          <div
            className="criteria flex w-[25%] flex-col items-center justify-end"
            key={score.key}
          >
            <div className="mb-2 w-full overflow-hidden text-ellipsis text-center font-secondary text-[10px] uppercase">
              {score.title}
            </div>
            <div className="flex justify-center">
              <ProgressCircle
                percent={score.value * 100}
                size={PROGRESS_CIRCLE_SIZE}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CriteriaProgressSection

import { useTranslation } from 'react-i18next'

import ThumbDownIcon from 'components/SVG/Icons/ThumbDownIcon'
import ThumbUpIcon from 'components/SVG/Icons/ThumbUpIcon'
import ClockIcon from 'components/SVG/Icons/ClockIcon'
import Badge from 'components/commons/Badge'
import { PROJECT_LIKE_STATUS } from 'types'

import theme from 'styles/theme'

const ReviewStatusBadge = ({ isLiked }: { isLiked?: string }) => {
  const { t } = useTranslation()

  if (isLiked === PROJECT_LIKE_STATUS.LIKED) {
    return (
      <Badge
        text={t('commonsComponents.reviewStatusBadge.reviewed')}
        icon={<ThumbUpIcon size={15} color={theme.colors.strongGrass} />}
        backgroundColor={theme.colors.grass}
        textColor={theme.colors.strongGrass}
      />
    )
  }

  if (isLiked === PROJECT_LIKE_STATUS.DISLIKED) {
    return (
      <Badge
        text={t('commonsComponents.reviewStatusBadge.reviewed')}
        icon={<ThumbDownIcon size={15} color="white" />}
        backgroundColor={theme.colors.coquelicot}
        textColor="white"
      />
    )
  }

  if (isLiked === undefined) {
    return (
      <Badge
        text={t('commonsComponents.reviewStatusBadge.pendingReview')}
        icon={<ClockIcon size={15} color={theme.colors.soil} />}
        backgroundColor={theme.colors.wind}
        textColor={theme.colors.soil}
      />
    )
  }

  return null
}

export default ReviewStatusBadge

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { Specification as SpecificationType } from 'types'
import getSpecification from 'actions/get-specification'

const useSpecification = (id: string) => {
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()

  const {
    data: specification,
    isLoading,
    error,
  } = useQuery<SpecificationType>({
    queryKey: ['specification', id],
    queryFn: () => getSpecification({ id }),
  })

  useEffect(() => {
    const handleLanguageChange = () => {
      queryClient.invalidateQueries({ queryKey: ['specification', id] })
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [i18n, queryClient, id])

  return {
    specification,
    isLoading,
    error,
  }
}

export default useSpecification

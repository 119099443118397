import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  return (
    <div className="flex items-center justify-center px-20 pb-10">
      <div className="text-center font-secondary text-strongWind">
        {t('commonsComponents.footer.allRightsReserved')} | Inicio {currentYear}
      </div>
    </div>
  )
}

export default Footer

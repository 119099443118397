import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { Table, Modal } from 'antd'
import { useState } from 'react'

import { CriterionData } from 'types'

const OwnersModal = ({ items }: { items: CriterionData[] }) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const transformedData = items?.map((item, index) => ({
    key: index,
    parcelle: t(item.title),
    proprietaires: (
      <ul className="list-disc pl-4">
        {item.items?.map((subItem, subIndex) => (
          <li key={subIndex} className="pb-2 last:pb-0">
            {subItem.value}
          </li>
        ))}
      </ul>
    ),
  }))

  const columns: ColumnsType = [
    {
      title: t('commons.parcels'),
      dataIndex: 'parcelle',
      key: 'parcelle',
    },
    {
      title: t('commons.owners'),
      dataIndex: 'proprietaires',
      key: 'proprietaires',
    },
  ]

  return (
    <div className="w-full">
      <div
        onClick={showModal}
        className="flex w-full justify-center bg-strongGrass px-2 py-3 text-sm text-white bg-blend-normal hover:cursor-pointer hover:brightness-90"
      >
        {t(
          'views.developer.dashboard.lead.leadPageRightPart.landownersCriteriaViewDetailsButtonMessage'
        )}
      </div>

      <Modal
        title={t(
          'views.developer.dashboard.lead.leadPageRightPart.landownersCriteriaViewDetailsModalTitle'
        )}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={800}
        bodyStyle={{ maxHeight: '70vh', overflowY: 'auto', paddingTop: 10 }}
      >
        <Table
          columns={columns}
          dataSource={transformedData}
          pagination={false}
          className="w-full"
        />
      </Modal>
    </div>
  )
}

export default OwnersModal

import { Dropdown, MenuProps } from 'antd'
import { ReactNode } from 'react'

import CaretDownIcon from 'components/SVG/Icons/CaretDownIcon'
import LoaderIcon from 'components/SVG/Icons/LoaderIcon'

import theme from 'styles/theme'

const Button = ({
  style = 'primary',
  children,
  onClick,
  loading,
  Icon,
  htmlType = 'button',
  disabled,
  className,
  items,
}: {
  style?: 'primary' | 'secondary' | 'disabled'
  children: ReactNode
  onClick?: () => void
  loading?: boolean
  Icon?: ({ size, color }: { size: number; color: string }) => React.JSX.Element
  htmlType?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  className?: string
  items?: MenuProps['items']
}) => {
  const isDisabled = style === 'disabled' || disabled

  let baseColor: string
  let hoverColor: string | undefined
  let loadingColor: string | undefined
  let textColor: string
  let iconColor: string
  let cursorClass: string | null = null

  switch (style) {
    case 'primary':
      baseColor = 'bg-corn'
      hoverColor = 'hover:bg-lightCorn'
      loadingColor = 'bg-lightCorn'
      textColor = 'text-soil'
      iconColor = theme.colors.soil
      break
    case 'secondary':
      baseColor = 'bg-soil'
      hoverColor = 'hover:bg-lightSoil'
      loadingColor = 'bg-lightSoil'
      textColor = 'text-white'
      iconColor = 'white'
      break
    case 'disabled':
      baseColor = 'bg-wind'
      hoverColor = undefined
      loadingColor = undefined
      textColor = 'text-strongWind'
      iconColor = theme.colors.strongWind
      break
    default:
      baseColor = 'corn'
      hoverColor = 'hover:bg-lightCorn'
      loadingColor = 'bg-lightCorn'
      textColor = 'text-soil'
      iconColor = theme.colors.soil
  }

  cursorClass = isDisabled || loading ? 'cursor-not-allowed' : 'cursor-pointer'
  const currentBgColor = loading ? loadingColor : baseColor

  const menuProps = {
    items,
  }

  const InnerButton = (
    <button
      type={htmlType}
      onClick={onClick}
      className={`${className} ${currentBgColor} ${hoverColor ? hoverColor : ''} ${cursorClass} flex h-[41px] items-center justify-center gap-2 rounded-lg px-4`}
      disabled={isDisabled || loading}
    >
      {Icon && (
        <div>
          <Icon color={iconColor} size={20} />
        </div>
      )}
      <div className={`font-secondary uppercase ${textColor} text-nowrap`}>
        {children}
      </div>
      {loading && (
        <div className="animate-spin">
          <LoaderIcon color={iconColor} size={20} />
        </div>
      )}

      {items && !loading && (
        <div>
          <CaretDownIcon color={iconColor} size={20} />
        </div>
      )}
    </button>
  )

  return items ? (
    <Dropdown menu={menuProps} open={!loading ? undefined : false}>
      {InnerButton}
    </Dropdown>
  ) : (
    InnerButton
  )
}

export default Button

import { motion } from 'framer-motion'

import { MenuItem } from 'views/developer/dashboard/components/Sidebar/MenuItem'
import { SideMenuCategory } from 'types'

export const MenuCategory = ({
  category,
  isExpanded,
  shouldAnimate,
}: {
  category: SideMenuCategory
  isExpanded: boolean
  shouldAnimate: boolean
}) => {
  return (
    <motion.div
      className="mb-8"
      initial={false}
      animate={shouldAnimate ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        className={`h-[28px] overflow-hidden pb-2 font-secondary text-sm uppercase text-strongWind`}
        initial={{ opacity: 0, width: 0 }}
        animate={
          shouldAnimate
            ? {
                opacity: isExpanded ? 1 : 0,
                width: isExpanded ? 'auto' : 0,
              }
            : { opacity: 0, width: 0 }
        }
        transition={{ duration: 0.2 }}
      >
        {category.title}
      </motion.div>
      {category.children && (
        <ul className="box-border flex flex-col gap-2 md:gap-0">
          {category.children.map((child) => (
            <MenuItem
              key={child.key ?? child.title}
              item={child}
              isExpanded={isExpanded}
              shouldAnimate={shouldAnimate}
            />
          ))}
        </ul>
      )}
    </motion.div>
  )
}

import { useQueryClient } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { useEffect } from 'react'

import DeveloperDashboardPageContent from 'views/developer/dashboard/components/DeveloperDashboardPageContent'
import LeadPageContent from 'views/developer/dashboard/lead/components/LeadPageContent'
import LeadPageHeader from 'views/developer/dashboard/lead/components/LeadPageHeader'
import WarningIcon from 'components/SVG/Icons/WarningIcon'
import { useProjectData } from 'hooks/useProjectData'
import i18n from 'utils/i18n'

const LeadPage = () => {
  const queryClient = useQueryClient()

  const params = useParams({
    from: '/developer/dashboard/project/$id',
  })

  const projectUuid = params?.id || ''
  const {
    project,
    isLoading,
    error: requestError,
    isViewable,
  } = useProjectData(projectUuid)

  useEffect(() => {
    const handleLanguageChange = () => {
      queryClient.invalidateQueries({ queryKey: ['project', projectUuid] })
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [i18n, queryClient, projectUuid])

  return (
    <DeveloperDashboardPageContent
      analyticsPageTitle={`Project ${projectUuid}`}
      isLoading={isLoading}
      error={requestError || !isViewable}
      errorMessage="It might not exist or you can't acccess it."
      errorTitle="This project is not accessible"
      errorIcon={WarningIcon}
      childrenExtra={
        project ? (
          <LeadPageHeader
            infoMemoUrl={project.infoMemo}
            projectUuid={project.uuid}
          />
        ) : undefined
      }
    >
      {project && (
        <LeadPageContent project={project} projectUuid={project.uuid} />
      )}
    </DeveloperDashboardPageContent>
  )
}

export default LeadPage

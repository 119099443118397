const Badge = ({
  text,
  icon,
  backgroundColor,
  textColor = 'black',
  className = '',
}: {
  text: string
  icon: React.ReactNode
  backgroundColor: string
  textColor?: string
  className?: string
}) => {
  return (
    <div
      className={`flex min-h-[30px] items-center justify-center gap-2 rounded-lg px-4 py-2 text-sm ${className} w-fit`}
      style={{ backgroundColor, color: textColor }}
    >
      {text} {icon}
    </div>
  )
}

export default Badge

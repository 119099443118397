import { useQueryClient } from '@tanstack/react-query'
import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import DashboardPageContent from 'views/developer/dashboard/components/DeveloperDashboardPageContent'
import ProjectList from 'views/developer/dashboard/leads/components/ProjectList'
import Filters, {
  FiltersType,
} from 'views/developer/dashboard/leads/components/Filters'
import useProjects from 'hooks/useProjects'
import i18n from 'utils/i18n'

const Leads = () => {
  const [filters, setFilters] = useState<FiltersType>({})
  const { projects, loading, getProjectLikeStatus, error } = useProjects()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const filteredProjects = useMemo(() => {
    if (!projects) return []

    return projects.filter((project) => {
      const projectLikedStatus = getProjectLikeStatus(project.uuid)

      if (!filters.review && !filters.specification) return true

      let reviewMatch = false
      let specificationMatch = false

      if (filters.review) {
        if (filters.review === 'pending' && projectLikedStatus === undefined) {
          reviewMatch = true
        } else if (filters.review === projectLikedStatus) {
          reviewMatch = true
        }
      } else {
        reviewMatch = true
      }

      if (filters.specification) {
        if (filters.specification === project.specification) {
          specificationMatch = true
        }
      } else {
        specificationMatch = true
      }

      return reviewMatch && specificationMatch
    })
  }, [projects, filters, getProjectLikeStatus])

  useEffect(() => {
    const handleLanguageChange = () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] })
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [i18n, queryClient])

  return (
    <DashboardPageContent
      analyticsPageTitle="Leads"
      isLoading={loading}
      error={error}
      errorTitle={t('errors.unableLoadProjects')}
      errorMessage={t('errors.tryAgainLater')}
      childrenExtra={<Filters setFilters={setFilters} filters={filters} />}
    >
      <ProjectList projects={filteredProjects} />
    </DashboardPageContent>
  )
}

export default Leads

const SubstractIcon = ({ size, color }: { size: number; color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      height={size}
      width={size}
    >
      <rect width="256" height="256" fill="none" />
      <circle
        cx="96"
        cy="96"
        r="72"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M167.6,88.4a72,72,0,1,1-79.2,79.2"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        x1="164.91"
        y1="116.91"
        x2="228.91"
        y2="180.91"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        x1="146.91"
        y1="146.91"
        x2="210.91"
        y2="210.91"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        x1="116.91"
        y1="164.91"
        x2="180.91"
        y2="228.91"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  )
}

export default SubstractIcon

const InicioLogomarkIcon = ({
  size,
  color,
}: {
  size: number | string
  color: string
}) => {
  const originalWidth = 130.84
  const originalHeight = 213.02

  const aspectRatio = originalWidth / originalHeight

  const width =
    typeof size === 'number'
      ? size * aspectRatio
      : `calc(${size} * ${aspectRatio})`

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="53.93 54.05 130.84 213.02"
      xmlSpace="preserve"
      width={width}
      height={size}
    >
      <g>
        <g>
          <path
            fill="#230C0B"
            d="M119.35,54.05c-36.13,0-65.42,29.29-65.42,65.42s29.29,65.42,65.42,65.42s65.42-29.29,65.42-65.42
                 S155.48,54.05,119.35,54.05z M119.35,159.55c-22.13,0-40.08-17.94-40.08-40.08S97.22,79.4,119.35,79.4s40.08,17.94,40.08,40.08
                 S141.48,159.55,119.35,159.55z"
          />
          <path
            fill="#230C0B"
            d="M171.47,184.9c-0.15-0.01-0.43-0.04-0.79,0c-14.43,1.41-27.55,9.7-35.17,22.33l-12.53,20.78
                 c-0.82,1.36-2.22,2.04-3.63,2.04c-1.4,0-2.8-0.68-3.63-2.04l-12.53-20.78c-7.62-12.63-20.74-20.91-35.17-22.33
                 c-0.36-0.04-0.64-0.01-0.79,0c-1.17,0.08-2.09,1.04-2.09,2.23c0,0.37,0.1,0.71,0.26,1.02l44.15,73.18
                 c1.6,2.65,4.14,4.69,7.15,5.41c0.89,0.21,1.77,0.31,2.65,0.31v0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0v0c0.87,0,1.76-0.1,2.65-0.31
                 c3.01-0.72,5.55-2.75,7.15-5.41l44.15-73.18c0.16-0.31,0.26-0.65,0.26-1.02C173.56,185.95,172.64,184.98,171.47,184.9z"
          />
        </g>
        <g>
          <circle fill={color} cx="119.35" cy="119.47" r="40.08" />
        </g>
      </g>
    </svg>
  )
}

export default InicioLogomarkIcon
